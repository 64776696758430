import React from "react";
import Button from "../../Components/Button";
import JsonToView from "../../NodeBuilder/JsonToView";
import SparetiveLine from "../../scripts/SparetiveLine";
import TestServiceExampleClass from "./TestServiceExampleClass";

const TestServiceExample = () => {
    const [data, setData] = React.useState({});

    const service = React.useMemo(() => new TestServiceExampleClass(), []);

    const getOne = async () => {
        service.setState("fetching one item ...");
        const response = await service.getItem("1");
        service.setState("item fetched..");
        setData(response);
    };

    const getAll = async () => {
        service.setState("fetching items ...");
        const response = await service.getItems();
        service.setState("items fetched..");
        setData(response);
    };

    const post = async () => {
        const response = await service.post("posts/1", { title: "test", body: "test", userId: 1 });
        setData(response);
    };

    return (
        <>
            <div className="px-md">
                <p className="text-center text-base text-orange">src / Examples / TestServiceExample.js</p>
                <p className="text-center text-crow text-2xl">Service Example </p>
                <JsonToView json={service} />
                <div className="row-center gap-md p-md">
                    <Button title="Get All" onClick={getAll} />
                    <Button title="Get One" onClick={getOne} />
                    <Button title="Post" onClick={post} />
                </div>
            </div>
            <SparetiveLine vertical storageKey="service-example" initialRatios={[0.3, 0.7]} />
            <div className="px-md mx-md overflow-auto scroller bg-throne rounded-xl">
                <JsonToView json={data} />
            </div>
        </>
    );
};

export default TestServiceExample;
