import React from "react";
import JsonToView from "../NodeBuilder/JsonToView";
import SparetiveLine from "../scripts/SparetiveLine";
import { FormBuilder } from "../ServiceProvider";

const FormExample = () => {
    const [formValues, setFormValues] = React.useState({});
    return (
        <>
            <div className="bg-lord p-md rounded-lg m-md" style={{ marginBottom: 0 }}>
                <p className="text-center text-base text-orange">src / Examples / FormExample.js</p>
                <p className="text-center text-crow text-2xl">Form Example </p>

                <FormBuilder
                    fields={formFields}
                    init={(initValues) => {
                        setFormValues(Object.entries(initValues).reduce((prev, [key, value]) => ({ ...prev, [key]: value.value }), {}));
                    }}
                    onChange={({ key, value }) => {
                        setFormValues((prev) => ({ ...prev, [key]: `${value}` }));
                    }}
                    className="form-container gap-xl row wrap"
                />
            </div>

            {/* Result */}
            <SparetiveLine vertical storageKey="form-example" initialRatios={[0.1, 0.9]} />
            <div className="bg-throne p-md rounded-lg overflow-hidden m-md" style={{ marginTop: 0 }}>
                <div>
                    <p className="text-center p-md text-lg">Result</p>
                    <JsonToView json={formValues} />
                </div>
                <SparetiveLine storageKey="form-example-two" initialRatios={[0.5, 0.5]} />
                <div className="overflow-auto ">
                    <p className="text-center p-md text-lg">Form Fields</p>
                    <JsonToView json={formFields} />
                </div>
                {/* Result */}
            </div>
        </>
    );
};

export default FormExample;
const formFields = [
    {
        className: "row-center rounded-md gap-md bg-throne py-xs px-md self-start items-cen",
        children: [
            {
                key: "name",
                type: "text",
                title: "Name",
                value: "",
                storageKey: "items-query",
                containerClass: "query-input",
            },
            {
                key: "age",
                type: "popupSelector",
                title: "Age",
                value: 1,
                storageKey: "q-age",
                options: [
                    { id: 1, title: "اكبر" },
                    { id: 2, title: "ازغر" },
                ],
            },
        ],
    },
];

const formFields_Two = [
    {
        className: "row-center rounded-md gap-md bg-throne py-xs px-md self-start items-cen",
        children: [
            {
                key: "name",
                type: "text",
                title: "Name",
                value: "",
                storageKey: "items-query",
                containerClass: "query-input",
            },
            {
                key: "age",
                type: "popupSelector",
                title: "Age",
                value: 1,
                storageKey: "q-age",
                options: [
                    { id: 1, title: "اكبر" },
                    { id: 2, title: "ازغر" },
                ],
            },
        ],
    },
    {
        className: "row-center rounded-md gap-md bg-throne py-xs px-md self-start items-center",
        children: [
            {
                key: "currencyId",
                type: "options",
                title: "العملة",
                value: 1,
                storageKey: "q-currencyId",
                options: [
                    { id: 1, title: "دينار" },
                    { id: 2, title: "دولار" },
                ],
            },
            {
                key: "sort",
                type: "selector",
                title: "Selector",
                value: 1,
                storageKey: "q-currencyId",
                options: [
                    { id: 1, title: "التاريخ" },
                    { id: 2, title: "السعر" },
                ],
            },
            {
                key: "deleted",
                type: "boolean",
                title: "المحذوقة",
                value: false,
                storageKey: "is-query",
            },
            {
                key: "from",
                type: "date",
                title: "التاريخ من",
                storageKey: "q-from",
            },
        ],
    },
];
