import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { getApi } from "../ServiceProvider/ApiService";
import InputText from "../Others/Test/Components/InputText";
import Language from "../Utils/Language";

const AuthView = ({ api }) => {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    let navigate = useNavigate();
    const loginBtnRef = React.useRef(null);

    const onClickLogin = async (e) => {
        if (userName === "" || password === "") return;
        if (loginBtnRef.current.classList["loading"]) return;
        loginBtnRef.current.classList.add("loading");
        // await getApi("hub")
        //     .post("representatives/login", { userName, password })
        //     .then((data) => {
        //         localStorage.setItem("logged-user", JSON.stringify(data));
        //         navigate("/");
        //         setTimeout(() => {
        //             window.location.reload();
        //         }, 10);
        //     })
        //     .catch(({ code }) => {
        //         loginBtnRef.current.classList.remove("loading");
        //         loginBtnRef.current.classList.add("error");
        //         loginBtnRef.current.firstChild.innerText = code === "ERR_NETWORK" || code === "ECONNABORTED" ? Language.NetError : Language.UserOrPasswordError;
        //         setTimeout(() => {
        //             loginBtnRef.current.classList.remove("error");
        //             loginBtnRef.current.firstChild.innerText = Language.Login;
        //         }, 1500);
        //     });
    };

    return (
        <div className="h-screen col overflow-auto" style={{ height: "100vh" }}>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    loginBtnRef.current.click();
                    return false;
                }}
                className="col px-4xl py-lg gap-xl justify-center mx-auto font-bold bg-prince shadow-lg rounded-lg m-auto">
                {/* {Svgs.Logo} */}
                <InputText required value={userName} placeholder={Language.UserName} setValue={setUserName} />
                <InputText required placeholder={Language.Password} value={password} setValue={setPassword} type="password" />
                <p type="subimt" ref={loginBtnRef} className="button m-auto bg-red text-white text-base" onClick={onClickLogin}>
                    <span>{Language.Login}</span>
                    <span id="loader-target" />
                </p>
                <input type="submit" style={{ display: "none" }} onClick={() => loginBtnRef.current.click()} />
            </form>
        </div>
    );
};

export default AuthView;
