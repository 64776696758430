import React from "react";
import Button from "../Components/Button";
import JsonToView from "../NodeBuilder/JsonToView";
import SparetiveLine from "../scripts/SparetiveLine";
import { ApiService } from "../ServiceProvider";

const ApiServiceExample = () => {
    const [data, setData] = React.useState({});
    const apiService = React.useMemo(
        () =>
            new ApiService({
                baseURL: "https://jsonplaceholder.typicode.com",
                headers: { "Content-Type": "application/json" },
                storageKey: "test-storage-key",
                storageType: "session" || "local",
            }),
        []
    );

    const getAll = async () => {
        const response = await apiService.get("posts");
        setData(response);
    };
    const getOne = async () => {
        const response = await apiService.get("posts/1");
        setData(response);
    };

    const post = async () => {
        const response = await apiService.post("posts/1", { title: "test", body: "test", userId: 1 });
        setData(response);
    };

    return (
        <>
            <div className="px-md">
                <p className="text-center text-base text-orange">src / Examples / ApiServiceExample.js</p>
                <p className="text-center text-crow text-2xl">Api Service Example </p>
                {/* <JsonToView className="grid bg-dolphin rounded-md" json={apiService} /> */}
                <div className="row-center gap-md p-md">
                    <Button title="Get All" onClick={getAll} />
                    <Button title="Get One" onClick={getOne} />
                    <Button title="Post" onClick={post} />
                </div>
            </div>
            <SparetiveLine vertical storageKey="api-service-example" initialRatios={[0.3, 0.7]} />

            <div className="p-md m-md overflow-auto scroller bg-throne rounded-xl">
                <JsonToView json={data} />
            </div>
        </>
    );
};

export default ApiServiceExample;
