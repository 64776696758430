import Language from "./Language";

export default class LogUtils {
    static handelQuerySort = (queryParams, initalSort) => {
        if (queryParams.sort) {
            let desc = queryParams.desc ? "-" : "";
            if (initalSort && queryParams.sort !== initalSort) {
                queryParams.sort = `${desc}${queryParams.sort},${desc}${initalSort}`;
            } else queryParams.sort = `${desc}${queryParams.sort}`;
            delete queryParams.desc;
        }
    };
    static Levels = [
        { title: "All Levels", id: "" },
        { title: "Debug", id: 0 },
        { title: "Info", id: 1 },
        { title: "Warn", id: 2 },
        { title: "Error", id: 3 },
        { title: "Fatal", id: 4 },
    ];

    static PagesSize = [
        { title: "25", id: 25 },
        { title: "50", id: 50 },
        { title: "75", id: 75 },
        { title: "100", id: 100 },
    ];

    static BaseLogSort = [
        { title: Language.Date, id: "onlineId" },
        { title: Language.LastVersion, id: "versionId" },
    ];

    static CompanySort = [
        { title: Language.Date, id: "createdAt" },
        { title: Language.LastVersion, id: "lastVersion" },
        { title: Language.LastLog, id: "lastLogAt" },
        { title: Language.LastError, id: "lastErrorAt" },
        { title: Language.LogsCount, id: "logsCount" },
        { title: Language.ErrorsCount, id: "errorsCount" },
        { title: Language.DonglesCount, id: "donglesCount" },
        { title: Language.UsersCount, id: "usersCount" },
    ];

    static WithoutErrorStatus = [
        { title: "_", id: "" },
        { title: "بدون To Do", id: 0 },
        { title: "بدون Solving", id: 1 },
        { title: "بدون Pending", id: 2 },
        { title: "بدون Solved", id: 3 },
        { title: "بدون Ignored", id: 4 },
        { title: "بدون Cannot Reproduce", id: 5 },
    ];

    static FilterErrorStatus = [
        { title: "All Status", id: "" },
        { title: "To Do", id: 0 },
        { title: "Solving", id: 1 },
        { title: "Pending", id: 2 },
        { title: "Solved", id: 3 },
        { title: "Ignored", id: 4 },
        { title: "Cannot Reproduce", id: 5 },
    ];

    static ErrorSort = [
        { title: Language.Date, id: "lastUpdate" },
        { title: Language.LastVersion, id: "maxVersion" },
        { title: "التكرار", id: "numberOfRepetitions" },
    ];

    static ErrorStateus = [
        { title: "To Do", id: 0 },
        { title: "Solving", id: 1 },
        { title: "Pending", id: 2 },
        { title: "Solved", id: 3 },
        { title: "Ignored", id: 4 },
        { title: "Cannot Reproduce", id: 5 },
    ];
    static DonglesType = [
        { title: "كل الدناكل", id: "" },
        { title: "رئيسي", id: true },
        { title: "فرعي", id: false },
    ];

    static DonglesSort = [
        { title: Language.Date, id: "createdAt" },
        { title: "عدد الشركات المستخدمة", id: "numberOfSoftwareUUIds" },
    ];

    static ErrorColors = ["#a875ff", "#ff4081", "#e50000", "#6BC950", "#D3D3D3", "#2ECD6F"];
}
