import React from "react";
import StorageService from "../ServiceProvider/StorageService";
import Urls from "../Services/Urls";

const StorageServiceTestView = () => {
    const service = React.useMemo(() => {
        const _service = StorageService.local({
            baseURL: Urls.reps,
            headers: {
                "Content-Type": "application/json",
                "App-Package": "com.morabaa.reps",
                Authorization: localStorage.token,
            },
            storageKey: "items",
            storageType: "session",
            url: "items",
        });
        setTimeout(() => {
            document.getElementById("lol").onclick = () => {
                _service.post("/items", {
                    lol: "not lol",
                    price: 1000,
                });
            };
        }, 100);
        return _service;
    }, []);

    return <div id="lol">CashTestView</div>;
};

export default StorageServiceTestView;
