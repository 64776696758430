import React from "react";
import { Route, Routes } from "react-router-dom";
import { Dashboard } from "../Pages";
import PagenatedExampleView from "../Examples/ServiceExample/PagenatedExampleView";
import ExamplesDashboard from "../Examples/ExamplesDashboard";
import FormExample from "../Examples/FormExample";
import SpliterTest from "../Examples/SpliterTest";
import Refresher from "../ServiceProvider/Refresher";
import RefresherExample from "../Examples/RefresherExample";
import BaseLog from "../Logs/BaseLog";
import ErrorLog from "../Logs/ErrorLog";
import ApiServiceExample from "../Examples/ApiServiceExample";
import TestServiceExample from "../Examples/ServiceExample/TestServiceViewExample";
import ServiceStateBuilder from "../Examples/ServiceStateBuilderExample";
import MessagesView from "../Logs/MessagesView";

export const _AuthedRoutes = [
    // { path: "/", element: <Dashboard />, title: "Dashboard" },
    // { path: "/addbill/:id", element: <AddBillView />, title: "AddBill" },
    // { path: "/cashtest", element: <StorageServiceTestView />, title: "cashtest" },
    // { path: "/accounts", element: <AccountsView />, title: "accounts" },
    { path: "/baselog", element: <BaseLog />, title: "BaseLog" },
    { path: "/", element: <MessagesView />, title: "Messages" },
    // { path: "/errorlog", element: <ErrorLog />, title: "ErrorLog" },
    // { path: "/examples", element: <p />, title: "Examples " },
    { path: "/examples/apiservice", element: <ApiServiceExample />, title: "Api Service" },
    { path: "/examples/service", element: <TestServiceExample />, title: "Service" },
    { path: "/examples/form", element: <FormExample />, title: "Form" },
    { path: "/examples/servicestate", element: <ServiceStateBuilder />, title: "Service State" },
    { path: "/examples/view", element: <PagenatedExampleView />, title: "Pagenation Service" },
    { path: "/examples/spliter", element: <SpliterTest />, title: "Spliter " },
    { path: "/examples/refresher", element: <RefresherExample />, title: "Refresher" },
];

const AuthedRoutes = () => {
    return (
        <>
            <Routes>
                {_AuthedRoutes.map((route) => (
                    <Route key={route.path} path={route.path} element={route.element} />
                ))}
            </Routes>
            {/* <ExamplesDashboard /> */}
        </>
    );
};

export default AuthedRoutes;
