import { PagenationService } from "../../ServiceProvider";
import Utils from "../../Utils/Utils";

const onResult = (data) => {
    var _logs = data.data;
    _logs.forEach((item) => {
        item.happenedAt = new Date(item.happenedAt).toLocaleString().split(",");
        const newVersion = item.version - 70000;
        if (newVersion > 0) item.version = newVersion;

        item.message.numberOfRepetitions = Utils.Formate(item.message.numberOfRepetitions);
        item.expand = item.message.content.length > 320 ? false : true;
    });
    return _logs;
    // data.count
};

export default class BaseLogService extends PagenationService {
    constructor() {
        const baseURL = "https://falcon.samwise.morabaa.com/api/v1";
        const headers = {};
        const endpoint = "logs";

        super({ baseURL, headers, endpoint, storageKey: "logs-storage", storageType: "session", useCash: true, onResult });
        this.endpoint = endpoint;
    }

    getItem = async (id) => {
        return await this.provider.get(`${this.endpoint}/id`);
    };
    deleteItem = async (id) => {
        return await this.provider.delete(`${this.endpoint}/id`);
    };
    addItem = async (item) => {
        return await this.provider.post(this.endpoint, item);
    };
    updateItem = async (item) => {
        return await this.provider.put(this.endpoint, item);
    };
}
