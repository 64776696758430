import { PagenationService } from "../../ServiceProvider";

export default class PagenationExampleService extends PagenationService {
    constructor() {
        // const baseURL = "https://falcon.samwise.morabaa.com/api/v1";
        // const endpoint = "companies";
        // const baseURL = "http://localhost:18699/api/v1";

        const baseURL = "https://repsapi.morabaaapps.com/api/v1";
        const headers = { "App-Package": "com.morabaa.reps", Authorization: localStorage.token };
        const endpoint = "items";

        super({
            baseURL,
            headers,
            onResult: (data) => {
                setTimeout(() => {
                    this.upateHeader && this.upateHeader();
                }, 100);
                // return data.map((item) => ({ ...item, price: item.sellPriceObject.price }));
            },
            endpoint,
            storageKey: "example-storage",
            storageType: "session",
            useCash: true,
        });
        this.endpoint = endpoint;
        this.id = "example-service";
    }

    getItem = async (id) => {
        return await this.apiService.get(`${this.endpoint}/id`);
    };
    deleteItem = async (id) => {
        return await this.apiService.delete(`${this.endpoint}/id`);
    };
    addItem = async (item) => {
        return await this.apiService.post(this.endpoint, item);
    };
    updateItem = async (item) => {
        return await this.apiService.put(this.endpoint, item);
    };
}
