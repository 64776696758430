import React from "react";
import Button from "../../Components/Button";
import { JsonBuilder } from "../../NodeBuilder/JsonParser";
import JsonToView from "../../NodeBuilder/JsonToView";
import PageStateKit from "../../PageStateKit/PageStateKit";
import { FormBuilder, PagenatedScroller } from "../../ServiceProvider";
import Grid from "../../ServiceProvider/Grid";
import MultiBuilderGrid from "../../ServiceProvider/MultiBuilderGrid";
import Language from "../../Utils/Language";
import PagenationExampleService from "./PagenationExampleService";

const PagenatedExampleView = () => {
    const service = React.useMemo(() => {
        const _service = new PagenationExampleService();

        // Add custom event to service
        _service.upateHeader = () => {
            const _header = {
                query: service.query.split("&").join(" & "),
                totalItems: service.items.length,
                queryParams: Object.entries(service.queryParams).map(([key, q]) => ({ [key]: q.value })),
                endpoint: service.endpoint,
                offset: service.offset,
                limit: service.limit,
            };
            service.setHeader(_header);
        };

        return _service;
    }, []);

    return (
        <PagenatedScroller service={service}>
            <div className="border-gray p-md rounded-lg m-md">
                <p className="text-center text-base text-orange">src / Examples / ServiceExample / ExampleView.js</p>
                <FormBuilder
                    fields={formFields}
                    init={(queryParams) => {
                        service.initQueryParams(queryParams);
                        setTimeout(() => {
                            service.upateHeader();
                        }, 500);
                    }}
                    onChange={({ key, value }) => service.updateQueryParams({ key, value })}
                    className="form-container gap-xl row wrap"
                />
                <TestActions service={service} />
            </div>
            <MultiBuilderGrid service={service} builders={Cards} />
            {/* <Grid service={service} ItemBuilder={Card} /> */}
        </PagenatedScroller>
    );
};

const Card = ({ item, updateItem }) => {
    return (
        <div
            className="bg-throne text-center rounded-lg p-xl shadow-lg"
            onClick={() => {
                updateItem({ ...item, name: "updated" });
            }}>
            {item.name}
            <br />
            price : {item.price}
        </div>
    );
};
const Cards = {
    Json: ({ item }) => {
        return (
            <div className="bg-throne rounded-2xl p-sm shadow-lg">
                <JsonToView json={item} />
            </div>
        );
    },
    Default: Card,
    Other: ({ item, updateItem }) => (
        <div
            className="bg-prince text-center text-cyan rounded-lg p-xl shadow-lg"
            onClick={() => {
                updateItem({ ...item, name: "updated" });
            }}>
            {item.name}
            <br />
            quantity:{item.quantity}
        </div>
    ),

    // details: ({ item }) => {
    //     return (
    //         <div className="bg-throne rounded-2xl p-sm shadow-lg">
    //             <JsonBuilder item={item} />
    //         </div>
    //     );
    // },
};

const formFields = [
    {
        className: "row-center rounded-md gap-md bg-throne py-xs px-md self-start items-center",
        children: [
            {
                key: "name",
                type: "text",
                title: Language.Search,
                value: "",
                storageKey: "items-query",
                containerClass: "query-input",
            },
            {
                key: "currencyId",
                type: "popupSelector",
                title: "Currency",
                value: 1,
                storageKey: "q-currencyId",
                options: [
                    { id: 1, title: "دينار" },
                    { id: 2, title: "دولار" },
                ],
            },
        ],
    },
    {
        className: "row-center rounded-md gap-md bg-throne py-xs px-md self-start items-cen",
        children: [
            {
                key: "currencyId",
                type: "options",
                title: "Currency",
                value: 1,
                storageKey: "q-currencyId",
                options: [
                    { id: 1, title: "دينار" },
                    { id: 2, title: "دولار" },
                ],
            },
            {
                key: "deleted",
                type: "boolean",
                title: "المحذوقة",
                value: false,
                storageKey: "is-query",
            },
        ],
    },
];

const TestActions = ({ service }) => {
    [service.header, service.setHeader] = React.useState({});
    const [showHeader, setShowHeader] = React.useState(false);
    const [showActions, setShowActions] = React.useState(false);
    const [showState, setShowState] = React.useState(false);

    return (
        <div className="p-md col items-start gap-md">
            <div className="row-center gap-md bg-prim px-md rounded-lg py-sm">
                <Button onClick={() => setShowState(!showState)} title={showState ? "X" : "Service Actions"} className="bg-frog text-black" />
                {showState && (
                    <>
                        <p className="text-shark text-base">Service Actions : </p>
                        <Button onClick={() => service.reload()} title="Reload" />
                        <Button onClick={() => service.search()} title="Search" />
                        <Button onClick={() => service.loadMore()} title="Load more " />
                        <Button onClick={() => service.clearStorage()} title="Clear storage " />
                        <div className="row-center bg-throne p-sm rounded-md gap-lg">
                            <p
                                onClick={() => service.updateQueryParams({ key: "new-query", value: document.getElementById("test-query-update").value })}
                                className={`px-sm py-xs bg-red text-white rounded-lg pointer `}>
                                {"Update query"}
                            </p>
                            <input id="test-query-update" type="text" className="" placeholder="new-query" />
                        </div>
                    </>
                )}
            </div>
            <div className="row-center gap-md bg-prim px-md rounded-lg py-sm">
                <Button onClick={() => setShowActions(!showActions)} title={showActions ? "X" : "Service State"} className="bg-frog text-black" />
                {showActions && (
                    <>
                        <p className="text-shark text-base">Service State : </p>
                        {Object.keys(PageStateKit)
                            .slice(0, Object.keys(PageStateKit).length - 1)
                            .map((stateName, i) => (
                                <Button key={i} onClick={() => service.setState(stateName)} title={stateName} className="text-white bg-jellyfish" />
                            ))}
                    </>
                )}
            </div>
            <div className="col gap-md bg-prim px-md rounded-lg py-sm">
                <Button onClick={() => setShowHeader(!showHeader)} title={showHeader ? "X" : "Service Info"} className="bg-frog text-black self-start" />
                {showHeader && (
                    <>
                        <p className="text-shark text-base text-center">Service Informations </p>
                        <JsonToView json={service.header} />
                    </>
                )}
            </div>
        </div>
    );
};

export default React.memo(PagenatedExampleView);
