import React from "react";

const LightCard = ({ item, color }) => (
    <p className="">
        <span className="font-bold px-md text-goat" style={{ letterSpacing: 3 }}>
            {item.message.numberOfRepetitions}
        </span>
        <span
            style={{
                boxShadow: `0 0 5px ${color}99`,
            }}
            className="text-base px-md rounded-full">
            {item.version}
        </span>
    </p>
);

export default LightCard;
