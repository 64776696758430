import React from "react";
import JsonToView from "../NodeBuilder/JsonToView";
import Refresher from "../ServiceProvider/Refresher";

const RefresherExample = () => {
    const service = { state: "none" };
    const onRefresh = () => alert("refreshed");

    const RandomJsonExample = {
        testKey: "testValue",
        lorem: "   lremp ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quod voluptate. Quisquam, quod voluptate. Quisquam, quod voluptate.Quisquam, quod voluptate. Quisquam, quod voluptate. Quisquam, quod voluptate. Quisquam, quod voluptate. Quisquam, quod voluptate. Quisquam,quod",
    };

    return (
        <Refresher className="m-md col gap-xl my-3xl h-fill" service={service} onRefresh={onRefresh}>
            <JsonToView
                className="self-center"
                json={{
                    pull: "to refresh",
                }}
            />
            <JsonToView json={RandomJsonExample} />
        </Refresher>
    );
};

export default RefresherExample;
