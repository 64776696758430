import React from "react";
import LightCard from "../Components/LightCard";
import TextCopy from "../Components/TextCopy";

const LevelColors = ["#A35200", "#1890FF", "#FAAD14", "#ec4245", "#FF1010"];
const LevelStatus = ["Debug", "Info", "Warn", "Error", "Fatal"];

const BaseLogCard = ({ item, hidecontent }) => {
    const color = LevelColors[item.level];
    return (
        <div className="logs-card font-bold relative">
            <div className="wrap rounded-md justify-between items-center px-md" style={{ marginTop: "var(--sm)" }}>
                <LightCard item={item} color={color} />
                <span className="text-shark" style={{ letterSpacing: 3 }}>
                    {item.happenedAt[0]}
                </span>
                <span className="text-shark text-left" style={{ letterSpacing: 3 }}>
                    {item.happenedAt[1]}
                </span>
            </div>
            <div className="wrap justify-between py-sm my-sm px-md " style={{ borderTop: "solid 1px var(--shark)" }}>
                {item.businessTitle ? <TextCopy info={item.businessTitle} infoTitle="Dongle" /> : item.dongleId && <TextCopy info={item.dongleId} />}
                {item.companyName && <TextCopy info={item.companyName} infoTitle="Company" />}
                <div className={`row-center gap-sm  ${item.isMain ? "text-red" : "text-orange"}`}>
                    <span className="text">( {item.loggedUser} )</span>
                    <p className="row-center">
                        <span className="px-sm">{item.dongle?.machineName}</span>
                        {/* <span className="material-icons text-lg">computer</span> */}
                    </p>
                </div>
            </div>
            {!hidecontent && (
                <>
                    <p className="text-left text-lg px-md" style={{ letterSpacing: 2, color: `${color}56` }}>
                        {LevelStatus[item.level]}
                        &nbsp;&nbsp;
                        {item.source}
                    </p>
                    <p
                        onClick={(e) => {
                            e.currentTarget.classList.toggle("cut-words");
                        }}
                        style={{ borderColor: `${color}56`, backgroundColor: `${color}33` }}
                        className={"logs-card-content cut-words"}>
                        {item.message.content}
                    </p>
                </>
            )}
        </div>
    );
};

export default BaseLogCard;
