import React from "react";
import { FormBuilder, Grid, PagenatedScroller } from "../ServiceProvider";
import Utils from "../Utils/Utils";
import BaseLogCard from "./Cards/BaseLogCard";
import Language from "./Language";
import LogUtils from "./LogUtils";
import { BaseLogService } from "./Services";

const BaseLog = () => {
    const service = React.useMemo(() => new BaseLogService(), []);
    return (
        <PagenatedScroller service={service}>
            <FormBuilder
                service={service}
                fields={formChildren}
                init={(queryParams) => {
                    LogUtils.handelQuerySort(queryParams, "lastUpdate");
                    service.initQueryParams(queryParams);
                }}
                onChange={({ key, value }) => {
                    if (key === "sort") LogUtils.handelQuerySort(service.queryParams, "lastUpdate");
                    service.updateQueryParams({ key, value });
                }}
            />
            <Grid service={service} ItemBuilder={BaseLogCard} className="log-grid" />
        </PagenatedScroller>
    );
};

const formChildren = [
    {
        className: "form-section",
        children: [
            {
                title: Language.CompanyName,
                key: "companyName",
                type: "text",
                value: "",
                sessionKey: "q-companyName",
                containerClass: "query-input",
            },
            {
                title: Language.DongleName,
                key: "businessTitle",
                type: "text",
                value: "",
                sessionKey: "q-businessTitle",
                containerClass: "query-input",
            },
            {
                title: Language.Source,
                key: "source",
                type: "text",
                value: "",
                sessionKey: "q-source",
                containerClass: "query-input",
            },
            {
                title: Language.TheMessage,
                key: "content",
                type: "text",
                value: "",
                sessionKey: "q-content",
                containerClass: "query-input",
            },
            {
                title: Language.DongleId,
                key: "dongle",
                type: "text",
                value: "",
                sessionKey: "q-dongle",
                containerClass: "query-input",
            },
            {
                key: "sort",
                type: "selector",
                title: Language.Sort,
                value: "onlineId",
                storageKey: "base-log-sort",
                options: LogUtils.BaseLogSort,
            },
            {
                key: "distinct",
                type: "boolean",
                title: Language.WithoutRepetitions,
                value: false,
                storageKey: "base-log-distinct",
            },
            {
                key: "level",
                type: "selector",
                title: Language.Level,
                value: "",
                storageKey: "base-log-level",
                options: LogUtils.Levels,
            },
        ],
    },
];
export default BaseLog;
