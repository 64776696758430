import { PagenationService } from "../../ServiceProvider";
import Utils from "../../Utils/Utils";

const onResult = (data) => {
    var messages = data.data;
    messages.forEach((message) => {
        message.COUNTBYBUSINESS = message.countByBusiness;
        message.NUMBEROFREPETITIONS = message.numberOfRepetitions;
        message.countByBusiness = Utils.Formate(message.countByBusiness);
        message.numberOfRepetitions = Utils.Formate(message.numberOfRepetitions);
    });
    return messages;
    // data.count
};

export default class MessagesService extends PagenationService {
    constructor() {
        const baseURL = "https://falcon.samwise.morabaa.com/api/v1";
        // const baseURL = "http://localhost:18699/api/v1";
        const headers = {};
        const endpoint = "logs/messages";

        super({ baseURL, headers, endpoint, storageKey: "logs-messages-storage", storageType: "local", useCash: true, onResult, limit: 2000 });
        this.endpoint = endpoint;
    }

    getItem = async (id) => {
        return await this.provider.get(`${this.endpoint}/id`);
    };
    deleteItem = async (id) => {
        return await this.provider.delete(`${this.endpoint}/id`);
    };
    addItem = async (item) => {
        return await this.provider.post(this.endpoint, item);
    };
    updateItem = async (item) => {
        return await this.provider.put(this.endpoint, item);
    };
}
