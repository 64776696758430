import { Capacitor } from "@capacitor/core";
import Logger from "./NodeBuilder/Logger";
import { P } from "./NodeBuilder/NodeBuilder";
import Root from "./scripts/Root";

// const clearCacheData = () => {
//     caches.keys().then((names) => {
//         names.forEach((name) => {
//             caches.delete(name);
//         });
//     });
//     console.log("Complete Cache Cleared");
// };
const FirstSetup = () => {
    // sessionStorage.clear();
    global.platform = Capacitor.getPlatform();
    Root.setTheme("");
    // handleErrors();

    const toggleThemeButton = P({
        className: "toggle-theme-button",
        innerText: Root.theme === "dark" ? "🌙" : "🌞",
        onclick: ({ currentTarget }) => {
            Root.toggleTheme();
            currentTarget.innerText = Root.theme === "dark" ? "🌙" : "🌞";
        },
    });
    // document.body.append(toggleThemeButton);
};

export default FirstSetup;

const handleErrors = () => {
    window.onerror = (message, file, line, column, errorObject) => {
        column = column || (window.event && window.event.errorCharacter);
        var stack = errorObject ? errorObject.stack : null;
        if (!stack) {
            var stack = [];
            errorObject["stack"] = stack;
        }
        const split = stack.split(".js");
        console.log(split);
        // stack = split[0] + split[1].slice(0, 10);
        Logger({ message, file, line, column, component: stack }, { type: "error" });
        return false;
    };
};

// document.body.append(dev);
// dev.style.left = 300 + "px";
// dev.style.top = 300 + "px";

// window.addEventListener("mousemove", (e) => {
//     // console.log(e.currentTarget);
//     dev.style.left = e.clientX - 20 + "px";
//     dev.style.top = e.clientY - 20 + "px";
// });
