import React from "react";
import { FormBuilder, Grid, PagenatedScroller } from "../ServiceProvider";
import Tabel from "../ServiceProvider/Tabel";
import Language from "./Language";
import { MessagesService } from "./Services";

const MessagesView = () => {
    const service = React.useMemo(() => new MessagesService(), []);
    service.search();

    return (
        <PagenatedScroller service={service}>
            {/* <FormBuilder
                fields={formChildren}
                init={(queryParams) => service.initQueryParams(queryParams)}
                onChange={({ key, value }) => service.updateQueryParams({ key, value })}
            /> */}
            <Tabel service={service} />
        </PagenatedScroller>
    );
};

const Card = ({ item }) => {
    return (
        <div id={item.id} className="bg-prim p-lg rounded-lg ">
            <p>{item.content}</p>
            <p>
                {Language.CountByBusinesses} {item.countByBusiness}
            </p>
            <p>
                {Language.Repetitions} {item.numberOfRepetitions}
            </p>
        </div>
    );
};

// const formChildren = [
//     {
//         className: "form-section",
//         children: [
//             {
//                 key: "query",
//                 type: "text",
//                 title: "Search...",
//                 value: "",
//                 storageKey: "search-query",
//                 containerClass: "query-input",
//             },
//             {
//                 key: "offset",
//                 type: "none",
//                 value: 0,
//             },
//             {
//                 key: "currencyId",
//                 type: "popupSelector",
//                 title: "Currency",
//                 value: 1,
//                 storageKey: "q-currencyId",
//                 options: [
//                     { id: 1, title: "دينار" },
//                     { id: 2, title: "دلار" },
//                 ],
//             },
//         ],
//     },
// ];
export default MessagesView;
