import React from "react";
import { FormBuilder, Grid, PagenatedScroller } from "../ServiceProvider";
import Language from "./Language";
import LogUtils from "./LogUtils";
import { ErrorLogService } from "./Services";

const ErrorLog = () => {
    const service = React.useMemo(() => new ErrorLogService(), []);
    return (
        <PagenatedScroller service={service}>
            <FormBuilder
                service={service}
                fields={formFields}
                init={(queryParams) => service.initQueryParams(queryParams)}
                onChange={({ key, value }) => service.updateQueryParams({ key, value })}
            />
            <Grid service={service} />
        </PagenatedScroller>
    );
};

const formFields = [
    {
        className: "form-section",
        children: [
            {
                title: Language.TheMessage,
                key: "content",
                type: "text",
                value: "",
                sessionKey: "q-content",
                containerClass: "query-input",
            },
            {
                key: "sort",
                type: "selector",
                title: Language.ErrorSort,
                value: "lastUpdate",
                storageKey: "base-log-sort",
                options: LogUtils.ErrorSort,
            },
            {
                key: "distinct",
                type: "boolean",
                title: Language.WithoutRepetitions,
                value: false,
                storageKey: "base-log-distinct",
            },
        ],
    },
];

export default ErrorLog;
