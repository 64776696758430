import React from "react";
import Sapreator from "../scripts/Sapreator";
import "./spliter.css";

const SparetiveLine = ({ vertical, sapreatorColors = ["#56799c", "#88d9e3"], minBefor, minAfter, initialRatios, storageKey }) => {
    return (
        <span
            aria-label="sapreator"
            style={{ borderRadius: "10px", padding: "3px", margin: "auto" }}
            ref={(ref) => {
                if (!ref || ref.childElementCount > 0) return;
                new Sapreator({ ref: ref.parentElement, vertical, sapreatorColors, spliter: ref, minBefor, minAfter, initialRatios, storageKey });
            }}
        />
    );
};

export default React.memo(SparetiveLine);
