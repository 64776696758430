import "./index.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
// import WebInit from "./Utils/WebInit";
import CapacitorInit from "./Utils/CapacitorInit";
import App from "./App";
import FirstSetup from "./FirstSetup";
import { messages } from "./messages";
// import Logger from "./NodeBuilder/Logger";
// import { ApiService, PagenationService, Service } from "./ServiceProvider";
// import { ItemsService, Urls } from "./Services";

// localStorage.setItem("token", "anfn6AxJdP18CbJdR96Q7dcgwpkNGrK9m3lHAIUfeGtLC8H7"); //hamed

console.log("INIT");
let token = localStorage.getItem("token");
if (!token) {
    token = "VZNjk7Iu9WiuwEP0UBXQpBK33wo5YgAIDAWWMUgkt8MH0psj";
    localStorage.setItem("token", token); // naruto
}
FirstSetup();

// window.addEventListener("popstate", (e) => {
//     alert("Back");
//     e.preventDefault();
// });

// const script = document.createElement("script");
// script.src = "./ServiceProvider/ApiService.js";
// script.async = true;
// document.body.append(script);

if (Capacitor.isNativePlatform()) CapacitorInit();
// else WebInit();

createRoot(document.getElementById("root")).render(
    <Router>
        <App token={token} />
    </Router>
);

console.log(messages);
if (!localStorage.getItem("logs-messages-storage-logs-messages-limit-2000")) {
    localStorage.setItem("logs-messages-storage-logs-messages-limit-2000", JSON.stringify(messages));
}
