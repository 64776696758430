import { ApiService } from "../../ServiceProvider";

export default class TestServiceExampleClass {
    endpoint = "posts";

    state = "none";
    setState = (_newState) => {
        this.state = _newState;
    };

    constructor() {
        this.apiService = new ApiService({
            baseURL: "https://jsonplaceholder.typicode.com",
            headers: { "Content-Type": "application/json" },
            storageKey: "test-storage-key",
            storageType: "session" || "local",
        });
    }

    getItems = async () => {
        return await this.apiService.get(this.endpoint);
    };
    getItem = async (id) => {
        return await this.apiService.get(`${this.endpoint}/${id}`);
    };
    deleteItem = async (id) => {
        return await this.apiService.delete(`${this.endpoint}/${id}`);
    };
    addItem = async (item) => {
        return await this.apiService.post(this.endpoint, item);
    };
    updateItem = async (item) => {
        return await this.apiService.put(this.endpoint, item);
    };
}
