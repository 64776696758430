import React from "react";
import ToogleTheme from "../Components/ToogleTheme";
import Info from "../NodeBuilder/Info";

const itemKeys = ["content", "countByBusiness", "numberOfRepetitions"];

const Tabel = ({ service, className = "tabel", onClick }) => {
    [service.items, service.setItems] = React.useState(service.items);
    // const itemKeys = React.useMemo(() => Object.keys(service.items[0]), [service.items]);
    service.setItem = React.useMemo(() => (item) => service.setItems((items) => items.map((i) => (i.id === item.id ? item : i))), []);
    return (
        <>
            <div className="row-center gap-2xl justify-between">
                <p className="font-bold text-lg">
                    <span>العدد الكلي :</span>
                    <span className="text-orange text-xl px-md">{service.items.length}</span>
                </p>
                <p
                    className="button self-center m-md"
                    onClick={async () => {
                        await service.reload();
                        Info({ title: "تم تحديث البيانات", type: "success" });
                    }}>
                    {"تحديث"}
                </p>
                <ToogleTheme />
            </div>
            <table id="table-container" className={className} onClick={onClick}>
                <thead>
                    <tr style={{ backgroundColor: "var(--prim)" }}>
                        <th className="text-orange">i</th>
                        {itemKeys.map((key, i) => (
                            <th
                                onClick={({ currentTarget }) => {
                                    const isAsc = currentTarget.classList.contains("asc");
                                    currentTarget.classList.toggle("asc");
                                    const SortKey = key.toLocaleUpperCase();
                                    service.setItems((prev) => [...prev.sort((a, b) => (isAsc ? a[SortKey] - b[SortKey] : b[SortKey] - a[SortKey]))]);
                                }}
                                key={i}>
                                {key}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {service.items.map((item, i) => (
                        <tr key={i}>
                            <td className="text-orange">{i}</td>
                            {itemKeys.map((key, i) => (
                                <td key={i}>{item[key]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};
export default React.memo(Tabel);
