export const messages = [
    {
        id: "3977d461-41a5-4011-84f9-065d0730902f",
        content: "Form Openned : FrmShortCut",
        numberOfRepetitions: "493,007",
        countByBusiness: "2,860",
        COUNTBYBUSINESS: 2860,
        NUMBEROFREPETITIONS: 493007,
    },
    {
        id: "dab6a560-d0a3-42ce-8b5e-a5910d7248fd",
        content: "Form Openned : frmMain",
        numberOfRepetitions: "492,706",
        countByBusiness: "2,860",
        COUNTBYBUSINESS: 2860,
        NUMBEROFREPETITIONS: 492706,
    },
    {
        id: "887ec5d4-43b0-4e6f-a07a-bdcddd0ffce7",
        content: "Form Closed : FrmloginWithPic",
        numberOfRepetitions: "433,843",
        countByBusiness: "2,805",
        COUNTBYBUSINESS: 2805,
        NUMBEROFREPETITIONS: 433843,
    },
    {
        id: "7a457018-aaa2-43d6-9ec1-840f8c52d28d",
        content: "Form Closed : frmWelcome",
        numberOfRepetitions: "584,218",
        countByBusiness: "2,752",
        COUNTBYBUSINESS: 2752,
        NUMBEROFREPETITIONS: 584218,
    },
    {
        id: "5dca61d0-f547-4638-bed9-54b265e06f36",
        content: "Form Openned : FrmloginWithPic",
        numberOfRepetitions: "460,352",
        countByBusiness: "2,690",
        COUNTBYBUSINESS: 2690,
        NUMBEROFREPETITIONS: 460352,
    },
    {
        id: "a16e1078-4b91-4ff6-b56b-578652e6d34c",
        content: "Form Openned : frmAskForBackup",
        numberOfRepetitions: "210,186",
        countByBusiness: "2,606",
        COUNTBYBUSINESS: 2606,
        NUMBEROFREPETITIONS: 210186,
    },
    {
        id: "cc219e1c-1153-444f-b31f-6f43d8714501",
        content: "Form Openned : FrmListG",
        numberOfRepetitions: "2,094,795",
        countByBusiness: "2,496",
        COUNTBYBUSINESS: 2496,
        NUMBEROFREPETITIONS: 2094795,
    },
    {
        id: "69328865-8011-4755-b604-e89dc0ed07ff",
        content: "Form Closed : FrmListG",
        numberOfRepetitions: "1,906,237",
        countByBusiness: "2,468",
        COUNTBYBUSINESS: 2468,
        NUMBEROFREPETITIONS: 1906237,
    },
    {
        id: "7dede24b-9829-40c3-af61-f73d99424abc",
        content: "Form Openned : frmItems",
        numberOfRepetitions: "1,567,114",
        countByBusiness: "2,410",
        COUNTBYBUSINESS: 2410,
        NUMBEROFREPETITIONS: 1567114,
    },
    {
        id: "3bf79cd5-c12b-444b-b333-ee071c7dd91d",
        content: "Form Closed : frmItems",
        numberOfRepetitions: "1,552,121",
        countByBusiness: "2,403",
        COUNTBYBUSINESS: 2403,
        NUMBEROFREPETITIONS: 1552121,
    },
    {
        id: "7c935020-fcf2-4e97-bde8-9f8d968f35d0",
        content: "Form Openned : frmItemView",
        numberOfRepetitions: "197,037",
        countByBusiness: "2,176",
        COUNTBYBUSINESS: 2176,
        NUMBEROFREPETITIONS: 197037,
    },
    {
        id: "899be5a3-9970-4ab3-bf94-e20f4e1c070e",
        content: "Form Closed : frmItemView",
        numberOfRepetitions: "176,438",
        countByBusiness: "2,138",
        COUNTBYBUSINESS: 2138,
        NUMBEROFREPETITIONS: 176438,
    },
    {
        id: "62ec8d43-7102-4fb7-8520-0f7d53bfe6f5",
        content: "Form Openned : frmAccountState",
        numberOfRepetitions: "364,371",
        countByBusiness: "2,086",
        COUNTBYBUSINESS: 2086,
        NUMBEROFREPETITIONS: 364371,
    },
    {
        id: "e9120778-7256-4d88-89ac-cdb59bdeb8dc",
        content: "Form Closed : frmAccountState",
        numberOfRepetitions: "349,740",
        countByBusiness: "2,064",
        COUNTBYBUSINESS: 2064,
        NUMBEROFREPETITIONS: 349740,
    },
    {
        id: "898a985c-3696-432c-94a3-0be50ffff16e",
        content: "Form Openned : frmReceipt",
        numberOfRepetitions: "403,758",
        countByBusiness: "2,025",
        COUNTBYBUSINESS: 2025,
        NUMBEROFREPETITIONS: 403758,
    },
    {
        id: "31745e12-7be7-4dea-8507-b4e61999f796",
        content: "Form Closed : frmReceipt",
        numberOfRepetitions: "392,900",
        countByBusiness: "2,017",
        COUNTBYBUSINESS: 2017,
        NUMBEROFREPETITIONS: 392900,
    },
    {
        id: "33b45a0c-e876-44e0-93bd-3c1aac040484",
        content: "Form Openned : FrmBillItems",
        numberOfRepetitions: "186,476",
        countByBusiness: "1,909",
        COUNTBYBUSINESS: 1909,
        NUMBEROFREPETITIONS: 186476,
    },
    {
        id: "2a4c3e29-5095-4eb7-9ffc-663095031609",
        content: "Form Closed : frmMain",
        numberOfRepetitions: "45,755",
        countByBusiness: "1,894",
        COUNTBYBUSINESS: 1894,
        NUMBEROFREPETITIONS: 45755,
    },
    {
        id: "6919aa5d-30f3-4148-b8c2-36932da19811",
        content: "Form Closed : FrmBillItems",
        numberOfRepetitions: "166,760",
        countByBusiness: "1,888",
        COUNTBYBUSINESS: 1888,
        NUMBEROFREPETITIONS: 166760,
    },
    {
        id: "48e76466-fe8e-4f80-9374-8e63bcdfad84",
        content: "Form Openned : FrmAddAccounts",
        numberOfRepetitions: "110,029",
        countByBusiness: "1,840",
        COUNTBYBUSINESS: 1840,
        NUMBEROFREPETITIONS: 110029,
    },
    {
        id: "6f043ff8-c2fc-4393-8af8-a67a3af48f76",
        content: "Form Closed : FrmShortCut",
        numberOfRepetitions: "39,104",
        countByBusiness: "1,839",
        COUNTBYBUSINESS: 1839,
        NUMBEROFREPETITIONS: 39104,
    },
    {
        id: "f67d1932-e7df-4c3b-b20e-b5c8cedc579c",
        content: "Form Closed : frmAskForBackup",
        numberOfRepetitions: "16,044",
        countByBusiness: "1,828",
        COUNTBYBUSINESS: 1828,
        NUMBEROFREPETITIONS: 16044,
    },
    {
        id: "60f00e92-c91a-4d97-891b-e76e1c61caad",
        content: "Form Closed : FrmAddAccounts",
        numberOfRepetitions: "104,292",
        countByBusiness: "1,815",
        COUNTBYBUSINESS: 1815,
        NUMBEROFREPETITIONS: 104292,
    },
    {
        id: "9aaf9579-1cde-473e-9488-c7068563c9fc",
        content: "Form Openned : frmCustomersSearch",
        numberOfRepetitions: "194,127",
        countByBusiness: "1,741",
        COUNTBYBUSINESS: 1741,
        NUMBEROFREPETITIONS: 194127,
    },
    {
        id: "db5a7cc7-f2cc-4102-be70-c1d0d5398d8c",
        content: "Form Closed : frmCustomersSearch",
        numberOfRepetitions: "194,116",
        countByBusiness: "1,741",
        COUNTBYBUSINESS: 1741,
        NUMBEROFREPETITIONS: 194116,
    },
    {
        id: "718a82fd-cb90-485f-910a-ea5cc54704fc",
        content: "Form Openned : frmClientBalance",
        numberOfRepetitions: "110,108",
        countByBusiness: "1,721",
        COUNTBYBUSINESS: 1721,
        NUMBEROFREPETITIONS: 110108,
    },
    {
        id: "1b8804c3-33bf-4dfd-977c-4f98c7d20ad7",
        content: "Form Closed : frmClientBalance",
        numberOfRepetitions: "104,529",
        countByBusiness: "1,689",
        COUNTBYBUSINESS: 1689,
        NUMBEROFREPETITIONS: 104529,
    },
    {
        id: "8909df08-84dc-41c8-9472-5c3b83686f9b",
        content: "Form Openned : frmPay",
        numberOfRepetitions: "143,122",
        countByBusiness: "1,682",
        COUNTBYBUSINESS: 1682,
        NUMBEROFREPETITIONS: 143122,
    },
    {
        id: "6c57e786-8ebd-4a81-a8e3-3f7d1af652f6",
        content: "Form Closed : frmPay",
        numberOfRepetitions: "139,174",
        countByBusiness: "1,669",
        COUNTBYBUSINESS: 1669,
        NUMBEROFREPETITIONS: 139174,
    },
    {
        id: "e3a586e2-8cc4-4f79-b3d9-03be4bd66cfd",
        content: "Form Openned : تقرير السندات ",
        numberOfRepetitions: "93,850",
        countByBusiness: "1,449",
        COUNTBYBUSINESS: 1449,
        NUMBEROFREPETITIONS: 93850,
    },
    {
        id: "75f6cd41-a79f-4eaa-8194-92fcdda0ecab",
        content: "Form Closed : تقرير السندات ",
        numberOfRepetitions: "90,873",
        countByBusiness: "1,438",
        COUNTBYBUSINESS: 1438,
        NUMBEROFREPETITIONS: 90873,
    },
    {
        id: "8de59901-1938-4cf5-99d4-e91996664d44",
        content: "Form Openned : frmAppSettings",
        numberOfRepetitions: "15,263",
        countByBusiness: "1,427",
        COUNTBYBUSINESS: 1427,
        NUMBEROFREPETITIONS: 15263,
    },
    {
        id: "59a1e52c-cc2f-4273-9c92-daa75960ae36",
        content: "Form Closed : frmAppSettings",
        numberOfRepetitions: "13,099",
        countByBusiness: "1,360",
        COUNTBYBUSINESS: 1360,
        NUMBEROFREPETITIONS: 13099,
    },
    {
        id: "2276a685-8717-4fe1-9f6c-7961f4795a76",
        content: "Form Openned : frmBackupAndRestore",
        numberOfRepetitions: "19,348",
        countByBusiness: "1,232",
        COUNTBYBUSINESS: 1232,
        NUMBEROFREPETITIONS: 19348,
    },
    {
        id: "aecebe63-7860-441d-9cce-75b7cf9ba039",
        content: "Form Openned : FrmItemsReports",
        numberOfRepetitions: "26,042",
        countByBusiness: "1,171",
        COUNTBYBUSINESS: 1171,
        NUMBEROFREPETITIONS: 26042,
    },
    {
        id: "ed4052a2-c6ee-4247-9fbe-17209e7ea804",
        content: "Form Openned : changeBalance",
        numberOfRepetitions: "46,815",
        countByBusiness: "1,160",
        COUNTBYBUSINESS: 1160,
        NUMBEROFREPETITIONS: 46815,
    },
    {
        id: "ce2400ea-9dbc-4c1b-aae0-29b23820c13d",
        content: "Form Closed : changeBalance",
        numberOfRepetitions: "46,779",
        countByBusiness: "1,160",
        COUNTBYBUSINESS: 1160,
        NUMBEROFREPETITIONS: 46779,
    },
    {
        id: "c7621641-9bab-4d77-bcaf-2b2b35ed3fb2",
        content: "Form Closed : FrmItemsReports",
        numberOfRepetitions: "24,455",
        countByBusiness: "1,147",
        COUNTBYBUSINESS: 1147,
        NUMBEROFREPETITIONS: 24455,
    },
    {
        id: "4bec2699-b94d-43ee-815f-936363e63859",
        content: "Form Openned : frmCurrency",
        numberOfRepetitions: "15,552",
        countByBusiness: "1,133",
        COUNTBYBUSINESS: 1133,
        NUMBEROFREPETITIONS: 15552,
    },
    {
        id: "0b4b20db-9dfa-40e5-9492-8e9b56743880",
        content: "Form Closed : frmCurrency",
        numberOfRepetitions: "15,027",
        countByBusiness: "1,126",
        COUNTBYBUSINESS: 1126,
        NUMBEROFREPETITIONS: 15027,
    },
    {
        id: "d9e0de6f-20f5-4a97-be90-280cd8eb8dc1",
        content: "Form Openned : frmShowingPrinters",
        numberOfRepetitions: "29,422",
        countByBusiness: "1,119",
        COUNTBYBUSINESS: 1119,
        NUMBEROFREPETITIONS: 29422,
    },
    {
        id: "049b83bd-8722-4932-a15d-bfec9940c4b7",
        content: "Form Closed : frmShowingPrinters",
        numberOfRepetitions: "27,654",
        countByBusiness: "1,098",
        COUNTBYBUSINESS: 1098,
        NUMBEROFREPETITIONS: 27654,
    },
    {
        id: "19f82fc8-0203-48c1-8d0a-029023b0cebb",
        content: "Form Openned : FrmSuperMarket",
        numberOfRepetitions: "297,301",
        countByBusiness: "1,078",
        COUNTBYBUSINESS: 1078,
        NUMBEROFREPETITIONS: 297301,
    },
    {
        id: "20641dc2-7a6e-4644-a3dc-a2c2c9431614",
        content: "Form Closed : FrmSuperMarket",
        numberOfRepetitions: "280,342",
        countByBusiness: "1,075",
        COUNTBYBUSINESS: 1075,
        NUMBEROFREPETITIONS: 280342,
    },
    {
        id: "da5f6bf5-a6d1-4351-b06f-5ef70c3dcbfc",
        content: "Form Openned : FrmMatchingDaily",
        numberOfRepetitions: "104,370",
        countByBusiness: "1,037",
        COUNTBYBUSINESS: 1037,
        NUMBEROFREPETITIONS: 104370,
    },
    {
        id: "3896509a-b828-4701-8291-b106776d4365",
        content: "Form Closed : FrmMatchingDaily",
        numberOfRepetitions: "96,432",
        countByBusiness: "1,027",
        COUNTBYBUSINESS: 1027,
        NUMBEROFREPETITIONS: 96432,
    },
    {
        id: "2c495dc4-1cd0-410d-af9d-57d558ca48a8",
        content: "Form Openned : frmExitWithEscapeDialog",
        numberOfRepetitions: "411,426",
        countByBusiness: "1,011",
        COUNTBYBUSINESS: 1011,
        NUMBEROFREPETITIONS: 411426,
    },
    {
        id: "520d34b8-e01e-4327-bb0c-f773d410b3b3",
        content: "Form Closed : frmExitWithEscapeDialog",
        numberOfRepetitions: "411,301",
        countByBusiness: "1,011",
        COUNTBYBUSINESS: 1011,
        NUMBEROFREPETITIONS: 411301,
    },
    {
        id: "650143ce-f8bb-429b-b306-3d46cabb0ad0",
        content: "Form Openned : frmCash",
        numberOfRepetitions: "77,798",
        countByBusiness: "1,008",
        COUNTBYBUSINESS: 1008,
        NUMBEROFREPETITIONS: 77798,
    },
    {
        id: "eb553576-4c1d-41e9-8358-c8ec487215fd",
        content: "Form Closed : frmCash",
        numberOfRepetitions: "75,677",
        countByBusiness: "1,000",
        COUNTBYBUSINESS: 1000,
        NUMBEROFREPETITIONS: 75677,
    },
    {
        id: "87b918e8-c1c6-4234-bafc-0771b4b2a9f3",
        content: "Form Openned : FrmProfit",
        numberOfRepetitions: "36,403",
        countByBusiness: "959",
        COUNTBYBUSINESS: 959,
        NUMBEROFREPETITIONS: 36403,
    },
    {
        id: "905775e0-f17e-4198-b9b5-90c2cd6d9dd0",
        content: "Form Closed : FrmProfit",
        numberOfRepetitions: "35,497",
        countByBusiness: "946",
        COUNTBYBUSINESS: 946,
        NUMBEROFREPETITIONS: 35497,
    },
    {
        id: "4e946eca-ebbe-4c96-8fde-1ff414f3a496",
        content: "Form Closed : frmBackupAndRestore",
        numberOfRepetitions: "13,497",
        countByBusiness: "890",
        COUNTBYBUSINESS: 890,
        NUMBEROFREPETITIONS: 13497,
    },
    {
        id: "163a677a-b3b7-4624-b2ae-9be8b3707a60",
        content: "Form Openned : FrmItemsTrans",
        numberOfRepetitions: "34,906",
        countByBusiness: "873",
        COUNTBYBUSINESS: 873,
        NUMBEROFREPETITIONS: 34906,
    },
    {
        id: "98a77857-71a2-4445-bc4c-27a23d5a12d5",
        content: "Form Openned : frmProfitsAndExpenses",
        numberOfRepetitions: "15,935",
        countByBusiness: "871",
        COUNTBYBUSINESS: 871,
        NUMBEROFREPETITIONS: 15935,
    },
    {
        id: "18c7d131-0362-4a04-b5da-414c288b767f",
        content: "Form Closed : FrmItemsTrans",
        numberOfRepetitions: "32,574",
        countByBusiness: "860",
        COUNTBYBUSINESS: 860,
        NUMBEROFREPETITIONS: 32574,
    },
    {
        id: "84bd873a-5026-406e-a0aa-13bd7175336d",
        content: "Form Closed : frmProfitsAndExpenses",
        numberOfRepetitions: "15,312",
        countByBusiness: "852",
        COUNTBYBUSINESS: 852,
        NUMBEROFREPETITIONS: 15312,
    },
    {
        id: "f56a3309-3ab1-4ba1-99bb-c629bc5e90c7",
        content: "Form Openned : FrmSelectVisa",
        numberOfRepetitions: "3,389",
        countByBusiness: "839",
        COUNTBYBUSINESS: 839,
        NUMBEROFREPETITIONS: 3389,
    },
    {
        id: "023252b1-bf06-4d68-88f8-7a303cf8e1ad",
        content: "Form Closed : FrmSelectVisa",
        numberOfRepetitions: "3,387",
        countByBusiness: "838",
        COUNTBYBUSINESS: 838,
        NUMBEROFREPETITIONS: 3387,
    },
    {
        id: "a751900d-3b3c-4a3b-9cdc-3de59908a79c",
        content: "Form Openned : FrmFurnish",
        numberOfRepetitions: "4,160",
        countByBusiness: "783",
        COUNTBYBUSINESS: 783,
        NUMBEROFREPETITIONS: 4160,
    },
    {
        id: "1d6db71d-1679-4a8e-b3cb-410ef5380fe7",
        content: "Form Closed : FrmFurnish",
        numberOfRepetitions: "4,152",
        countByBusiness: "783",
        COUNTBYBUSINESS: 783,
        NUMBEROFREPETITIONS: 4152,
    },
    {
        id: "788afe0d-b094-4c5d-8a5c-ac8909bca8e6",
        content: "Form Openned : frmUser",
        numberOfRepetitions: "4,861",
        countByBusiness: "776",
        COUNTBYBUSINESS: 776,
        NUMBEROFREPETITIONS: 4861,
    },
    {
        id: "ef2107c8-e7c2-4b5f-b250-6a7435080a29",
        content: "Form Closed : frmUser",
        numberOfRepetitions: "4,384",
        countByBusiness: "737",
        COUNTBYBUSINESS: 737,
        NUMBEROFREPETITIONS: 4384,
    },
    {
        id: "b8b805de-a636-488c-870e-80e36622cdf6",
        content: "Form Openned : FrmGeneralReportSettings",
        numberOfRepetitions: "5,059",
        countByBusiness: "736",
        COUNTBYBUSINESS: 736,
        NUMBEROFREPETITIONS: 5059,
    },
    {
        id: "2d1ef836-b000-41eb-8a1f-54afb7ced09c",
        content: "Form Openned : FrmProgramColors",
        numberOfRepetitions: "4,521",
        countByBusiness: "732",
        COUNTBYBUSINESS: 732,
        NUMBEROFREPETITIONS: 4521,
    },
    {
        id: "ee89225c-cc0c-48ec-b21d-196f65e48bb0",
        content: "Form Openned : frmFillingSelectionForBillItem",
        numberOfRepetitions: "1,578,452",
        countByBusiness: "726",
        COUNTBYBUSINESS: 726,
        NUMBEROFREPETITIONS: 1578452,
    },
    {
        id: "9db408e1-12e7-49e1-8d49-19f6cc7eb871",
        content: "Form Closed : frmFillingSelectionForBillItem",
        numberOfRepetitions: "1,578,332",
        countByBusiness: "726",
        COUNTBYBUSINESS: 726,
        NUMBEROFREPETITIONS: 1578332,
    },
    {
        id: "3745061e-274f-44ac-a8cf-f6d2c445d59c",
        content: "Form Closed : FrmGeneralReportSettings",
        numberOfRepetitions: "4,736",
        countByBusiness: "714",
        COUNTBYBUSINESS: 714,
        NUMBEROFREPETITIONS: 4736,
    },
    {
        id: "4a3ab35f-9054-4ea9-87b0-7586363dda10",
        content: "Form Openned : FrmColumnVisibilty",
        numberOfRepetitions: "5,068",
        countByBusiness: "710",
        COUNTBYBUSINESS: 710,
        NUMBEROFREPETITIONS: 5068,
    },
    {
        id: "d978400f-b043-4bee-91db-ef3b38a0d9a2",
        content: "Form Closed : FrmColumnVisibilty",
        numberOfRepetitions: "5,065",
        countByBusiness: "708",
        COUNTBYBUSINESS: 708,
        NUMBEROFREPETITIONS: 5065,
    },
    {
        id: "605074e8-7f20-46cf-98ea-bc6e80d6ffd0",
        content: "Form Closed : frmStores",
        numberOfRepetitions: "3,957",
        countByBusiness: "689",
        COUNTBYBUSINESS: 689,
        NUMBEROFREPETITIONS: 3957,
    },
    {
        id: "16bfb013-a52f-4bd8-941d-5d55c92eea72",
        content: "Form Openned : FrmDeletedList",
        numberOfRepetitions: "4,215",
        countByBusiness: "666",
        COUNTBYBUSINESS: 666,
        NUMBEROFREPETITIONS: 4215,
    },
    {
        id: "07453f9f-3888-4438-b770-a96576c847ed",
        content: "Form Closed : FrmDeletedList",
        numberOfRepetitions: "4,086",
        countByBusiness: "660",
        COUNTBYBUSINESS: 660,
        NUMBEROFREPETITIONS: 4086,
    },
    {
        id: "cdb3a0cb-e637-4603-9a75-6c768dae2583",
        content: "Form Openned : frmStores",
        numberOfRepetitions: "3,838",
        countByBusiness: "644",
        COUNTBYBUSINESS: 644,
        NUMBEROFREPETITIONS: 3838,
    },
    {
        id: "39c2233d-aa4a-4baa-933e-6f5b1c79f86f",
        content: "Form Openned : FrmUserPerms",
        numberOfRepetitions: "5,389",
        countByBusiness: "638",
        COUNTBYBUSINESS: 638,
        NUMBEROFREPETITIONS: 5389,
    },
    {
        id: "e812bf57-0cbf-44d1-b506-89209ccfcc93",
        content: "Form Openned : FrmUserShortCut",
        numberOfRepetitions: "2,982",
        countByBusiness: "636",
        COUNTBYBUSINESS: 636,
        NUMBEROFREPETITIONS: 2982,
    },
    {
        id: "f2fbfd8e-7911-4c8a-b62e-d4168f63087a",
        content: "Form Closed : FrmUserPerms",
        numberOfRepetitions: "5,078",
        countByBusiness: "632",
        COUNTBYBUSINESS: 632,
        NUMBEROFREPETITIONS: 5078,
    },
    {
        id: "de9a1154-a0b7-414a-a9c0-0875653726c0",
        content: "Form Openned : frmAccountsSummary",
        numberOfRepetitions: "8,809",
        countByBusiness: "629",
        COUNTBYBUSINESS: 629,
        NUMBEROFREPETITIONS: 8809,
    },
    {
        id: "c292d32a-be59-4e44-a833-fe31785d68c6",
        content: "Form Closed : FrmUserShortCut",
        numberOfRepetitions: "2,864",
        countByBusiness: "629",
        COUNTBYBUSINESS: 629,
        NUMBEROFREPETITIONS: 2864,
    },
    {
        id: "1786650c-4407-4f3b-a63a-f2d1fd6bb5cb",
        content: "Form Closed : frmAccountsSummary",
        numberOfRepetitions: "8,498",
        countByBusiness: "621",
        COUNTBYBUSINESS: 621,
        NUMBEROFREPETITIONS: 8498,
    },
    {
        id: "aed3e6cd-c322-4a23-83f2-0ff5ce2f6d77",
        content: "Form Openned : frmChangePassword",
        numberOfRepetitions: "2,236",
        countByBusiness: "618",
        COUNTBYBUSINESS: 618,
        NUMBEROFREPETITIONS: 2236,
    },
    {
        id: "e827591c-73e2-4812-b3b0-f9b3810e3ce5",
        content: "Form Openned : frmMatchingDailyDetails",
        numberOfRepetitions: "46,784",
        countByBusiness: "617",
        COUNTBYBUSINESS: 617,
        NUMBEROFREPETITIONS: 46784,
    },
    {
        id: "4310fa6f-a925-4809-8c34-0b910f32f80a",
        content: "Form Closed : FrmProgramColors",
        numberOfRepetitions: "2,149",
        countByBusiness: "614",
        COUNTBYBUSINESS: 614,
        NUMBEROFREPETITIONS: 2149,
    },
    {
        id: "a03a6af4-c8f2-4da2-ae9d-7273878cdbdd",
        content: "Form Closed : frmMatchingDailyDetails",
        numberOfRepetitions: "46,111",
        countByBusiness: "613",
        COUNTBYBUSINESS: 613,
        NUMBEROFREPETITIONS: 46111,
    },
    {
        id: "73a1d86c-6a1d-4820-b8c0-490561c962c0",
        content: "Form Openned : frmClientBalance2C",
        numberOfRepetitions: "17,702",
        countByBusiness: "608",
        COUNTBYBUSINESS: 608,
        NUMBEROFREPETITIONS: 17702,
    },
    {
        id: "826fdfcd-2b89-4f58-a5d0-35b180850c30",
        content: "Form Closed : frmClientBalance2C",
        numberOfRepetitions: "16,766",
        countByBusiness: "595",
        COUNTBYBUSINESS: 595,
        NUMBEROFREPETITIONS: 16766,
    },
    {
        id: "b6ad276a-0e0f-416d-b47f-2123937e712a",
        content: "Form Openned : FrmSettings",
        numberOfRepetitions: "1,990",
        countByBusiness: "591",
        COUNTBYBUSINESS: 591,
        NUMBEROFREPETITIONS: 1990,
    },
    {
        id: "361403cc-0b27-4c31-be0c-d4bb93928a92",
        content: "Form Closed : frmChangePassword",
        numberOfRepetitions: "1,979",
        countByBusiness: "570",
        COUNTBYBUSINESS: 570,
        NUMBEROFREPETITIONS: 1979,
    },
    {
        id: "e8180eeb-e12e-42af-8ce3-95faef11b7bb",
        content: "Form Openned : FrmPriceCheckerSettings",
        numberOfRepetitions: "1,686",
        countByBusiness: "547",
        COUNTBYBUSINESS: 547,
        NUMBEROFREPETITIONS: 1686,
    },
    {
        id: "efe0dc01-11e5-4404-a508-f3bde309d613",
        content: "Form Closed : FrmPriceCheckerSettings",
        numberOfRepetitions: "1,609",
        countByBusiness: "538",
        COUNTBYBUSINESS: 538,
        NUMBEROFREPETITIONS: 1609,
    },
    {
        id: "083ff913-e407-4750-90e8-10b304415e67",
        content: "Form Openned : FrmBarcode",
        numberOfRepetitions: "121,254",
        countByBusiness: "527",
        COUNTBYBUSINESS: 527,
        NUMBEROFREPETITIONS: 121254,
    },
    {
        id: "e2f7425d-7dc4-4296-a7ed-e75ddb34f442",
        content: "Form Openned : FrmItemMovments",
        numberOfRepetitions: "8,747",
        countByBusiness: "523",
        COUNTBYBUSINESS: 523,
        NUMBEROFREPETITIONS: 8747,
    },
    {
        id: "783b21b7-6edf-4c69-80b5-e4c788944145",
        content: "Form Closed : FrmBarcode",
        numberOfRepetitions: "118,231",
        countByBusiness: "520",
        COUNTBYBUSINESS: 520,
        NUMBEROFREPETITIONS: 118231,
    },
    {
        id: "c4288e87-e117-4647-8765-3eff42769e8c",
        content: "Form Closed : FrmItemMovments",
        numberOfRepetitions: "8,204",
        countByBusiness: "519",
        COUNTBYBUSINESS: 519,
        NUMBEROFREPETITIONS: 8204,
    },
    {
        id: "9732c67b-0e7b-4bf2-994d-a342fcb695cc",
        content: "Form Openned : FrmItemDeleted",
        numberOfRepetitions: "6,752",
        countByBusiness: "515",
        COUNTBYBUSINESS: 515,
        NUMBEROFREPETITIONS: 6752,
    },
    {
        id: "a0157ed4-e63b-4529-8486-b8478c6bfea2",
        content: "Form Openned : FrmCashingDaily",
        numberOfRepetitions: "18,801",
        countByBusiness: "508",
        COUNTBYBUSINESS: 508,
        NUMBEROFREPETITIONS: 18801,
    },
    {
        id: "b97f8701-b36f-4767-b5e1-51924dff513a",
        content: "Form Closed : FrmCashingDaily",
        numberOfRepetitions: "17,560",
        countByBusiness: "501",
        COUNTBYBUSINESS: 501,
        NUMBEROFREPETITIONS: 17560,
    },
    {
        id: "f41379b7-0fff-470d-9406-d4d95751db62",
        content: "Form Closed : FrmItemDeleted",
        numberOfRepetitions: "6,083",
        countByBusiness: "501",
        COUNTBYBUSINESS: 501,
        NUMBEROFREPETITIONS: 6083,
    },
    {
        id: "50e51cf8-b6f1-4716-a6fe-f8d7106a694b",
        content: "Form Openned : FrmAbout",
        numberOfRepetitions: "1,633",
        countByBusiness: "501",
        COUNTBYBUSINESS: 501,
        NUMBEROFREPETITIONS: 1633,
    },
    {
        id: "18c702ad-ace0-4e8c-8c2f-0613f0cee01c",
        content: "Form Openned : FrmPupup",
        numberOfRepetitions: "1,249,244",
        countByBusiness: "492",
        COUNTBYBUSINESS: 492,
        NUMBEROFREPETITIONS: 1249244,
    },
    {
        id: "9bd5e32b-ca2f-4d35-9dce-c9b311fc8b19",
        content: "Form Closed : FrmPupup",
        numberOfRepetitions: "1,248,997",
        countByBusiness: "492",
        COUNTBYBUSINESS: 492,
        NUMBEROFREPETITIONS: 1248997,
    },
    {
        id: "d5fad57e-3956-41da-8f8e-41d4b542cd2a",
        content: "Form Closed : FrmAbout",
        numberOfRepetitions: "1,533",
        countByBusiness: "484",
        COUNTBYBUSINESS: 484,
        NUMBEROFREPETITIONS: 1533,
    },
    {
        id: "58ce667b-b1d9-4c1b-bdf7-08513220bd2d",
        content: "Form Openned : frmUploadReports",
        numberOfRepetitions: "2,667",
        countByBusiness: "464",
        COUNTBYBUSINESS: 464,
        NUMBEROFREPETITIONS: 2667,
    },
    {
        id: "800df27c-ea31-44b0-a1da-1b00af126cf4",
        content: "Form Openned : FrmDeletedAccounts",
        numberOfRepetitions: "2,005",
        countByBusiness: "462",
        COUNTBYBUSINESS: 462,
        NUMBEROFREPETITIONS: 2005,
    },
    {
        id: "3e5ae368-1ffa-4e66-b7d8-2b83fedf9406",
        content: "Form Closed : frmUploadReports",
        numberOfRepetitions: "2,557",
        countByBusiness: "458",
        COUNTBYBUSINESS: 458,
        NUMBEROFREPETITIONS: 2557,
    },
    {
        id: "50f32ea2-f6f3-4974-aac4-6b71a1e2e6f1",
        content: "Form Openned : FrmOnlineInfo",
        numberOfRepetitions: "5,210",
        countByBusiness: "448",
        COUNTBYBUSINESS: 448,
        NUMBEROFREPETITIONS: 5210,
    },
    {
        id: "f1e65713-50eb-4cf1-94f5-a64e319187cf",
        content: "Form Closed : FrmOnlineInfo",
        numberOfRepetitions: "5,193",
        countByBusiness: "447",
        COUNTBYBUSINESS: 447,
        NUMBEROFREPETITIONS: 5193,
    },
    {
        id: "e21d11b7-64eb-49ea-a9b8-e9e6b752f5ce",
        content: "Form Closed : FrmDeletedAccounts",
        numberOfRepetitions: "1,934",
        countByBusiness: "447",
        COUNTBYBUSINESS: 447,
        NUMBEROFREPETITIONS: 1934,
    },
    {
        id: "a6f5efd0-1dc5-4f39-b84e-9faeff0a8942",
        content: "Form Openned : frmViewTask",
        numberOfRepetitions: "2,161",
        countByBusiness: "443",
        COUNTBYBUSINESS: 443,
        NUMBEROFREPETITIONS: 2161,
    },
    {
        id: "4cb16b99-75a5-4632-8e7c-b5329b544228",
        content: "Form Openned : FrmAccountState2C",
        numberOfRepetitions: "26,822",
        countByBusiness: "437",
        COUNTBYBUSINESS: 437,
        NUMBEROFREPETITIONS: 26822,
    },
    {
        id: "3d4065d0-a4c8-4688-8792-687f8eec5292",
        content: "Form Closed : FrmAccountState2C",
        numberOfRepetitions: "25,395",
        countByBusiness: "433",
        COUNTBYBUSINESS: 433,
        NUMBEROFREPETITIONS: 25395,
    },
    {
        id: "eaee0259-786a-47aa-968c-af4b23fafb72",
        content: "Form Closed : frmViewTask",
        numberOfRepetitions: "2,116",
        countByBusiness: "430",
        COUNTBYBUSINESS: 430,
        NUMBEROFREPETITIONS: 2116,
    },
    {
        id: "baa8be33-8554-4bc2-b2b2-eb31a1c3ad29",
        content: "Form Openned : FrmUploadAccounts",
        numberOfRepetitions: "3,036",
        countByBusiness: "428",
        COUNTBYBUSINESS: 428,
        NUMBEROFREPETITIONS: 3036,
    },
    {
        id: "5e39a99b-08e5-4fc6-a9de-cd5b07c1a058",
        content: "Form Openned : FrmExch",
        numberOfRepetitions: "11,897",
        countByBusiness: "426",
        COUNTBYBUSINESS: 426,
        NUMBEROFREPETITIONS: 11897,
    },
    {
        id: "50220198-8fad-4fb8-be6e-f976348962f3",
        content: "Form Closed : FrmExch",
        numberOfRepetitions: "11,529",
        countByBusiness: "421",
        COUNTBYBUSINESS: 421,
        NUMBEROFREPETITIONS: 11529,
    },
    {
        id: "c0cbb931-e5d7-4577-bdbc-8d7dc028db99",
        content: "Form Closed : FrmUploadAccounts",
        numberOfRepetitions: "2,774",
        countByBusiness: "414",
        COUNTBYBUSINESS: 414,
        NUMBEROFREPETITIONS: 2774,
    },
    {
        id: "e87c4d30-2d1a-4865-9209-9e19da0968c9",
        content: "Form Closed : FrmSettings",
        numberOfRepetitions: "1,207",
        countByBusiness: "408",
        COUNTBYBUSINESS: 408,
        NUMBEROFREPETITIONS: 1207,
    },
    {
        id: "26cea2bf-01ec-47ee-8c8b-ddf113942f34",
        content: "Form Openned : frmGroup",
        numberOfRepetitions: "3,541",
        countByBusiness: "406",
        COUNTBYBUSINESS: 406,
        NUMBEROFREPETITIONS: 3541,
    },
    {
        id: "d9fd1502-6a60-405d-809a-85b3e29914d4",
        content: "Form Closed : frmGroup",
        numberOfRepetitions: "3,535",
        countByBusiness: "406",
        COUNTBYBUSINESS: 406,
        NUMBEROFREPETITIONS: 3535,
    },
    {
        id: "c2f3c11b-660a-49e7-bfcc-e3f30956c99a",
        content: "Form Closed : frmCompanies",
        numberOfRepetitions: "5,593",
        countByBusiness: "402",
        COUNTBYBUSINESS: 402,
        NUMBEROFREPETITIONS: 5593,
    },
    {
        id: "daa64e14-f748-4bbe-bb91-9a8100cd909f",
        content: "Form Openned : frmCompanies",
        numberOfRepetitions: "5,592",
        countByBusiness: "402",
        COUNTBYBUSINESS: 402,
        NUMBEROFREPETITIONS: 5592,
    },
    {
        id: "362c86fd-f27e-4599-b4f9-2a0fe913856f",
        content: "Form Openned : FrmOnlineLoginOrRegistar",
        numberOfRepetitions: "1,877",
        countByBusiness: "398",
        COUNTBYBUSINESS: 398,
        NUMBEROFREPETITIONS: 1877,
    },
    {
        id: "1ceba315-1e58-410d-afdb-034591169c51",
        content: "Form Closed : FrmOnlineLoginOrRegistar",
        numberOfRepetitions: "1,871",
        countByBusiness: "397",
        COUNTBYBUSINESS: 397,
        NUMBEROFREPETITIONS: 1871,
    },
    {
        id: "0661b6bc-4505-4a9e-959b-31bb38f683b0",
        content: "Form Openned : FrmMoneyMovment",
        numberOfRepetitions: "5,162",
        countByBusiness: "388",
        COUNTBYBUSINESS: 388,
        NUMBEROFREPETITIONS: 5162,
    },
    {
        id: "c41619e9-c45d-431b-93f4-2f2702c74f62",
        content: "Form Openned : frmReceiptMulti",
        numberOfRepetitions: "5,183",
        countByBusiness: "386",
        COUNTBYBUSINESS: 386,
        NUMBEROFREPETITIONS: 5183,
    },
    {
        id: "486f33a6-c320-4a71-a1ed-ace81850f526",
        content: "Form Closed : FrmMoneyMovment",
        numberOfRepetitions: "4,981",
        countByBusiness: "383",
        COUNTBYBUSINESS: 383,
        NUMBEROFREPETITIONS: 4981,
    },
    {
        id: "ac7db341-4f67-464d-a022-eb480685f940",
        content: "Form Closed : frmReceiptMulti",
        numberOfRepetitions: "4,956",
        countByBusiness: "383",
        COUNTBYBUSINESS: 383,
        NUMBEROFREPETITIONS: 4956,
    },
    {
        id: "cc95b50a-ad00-4b99-a1b6-971d52de2a6e",
        content: "Form Openned : FrmItemsProfits",
        numberOfRepetitions: "3,823",
        countByBusiness: "381",
        COUNTBYBUSINESS: 381,
        NUMBEROFREPETITIONS: 3823,
    },
    {
        id: "d396554a-553a-4e9e-8299-2e91492c99f5",
        content: "Form Closed : FrmItemsProfits",
        numberOfRepetitions: "3,648",
        countByBusiness: "375",
        COUNTBYBUSINESS: 375,
        NUMBEROFREPETITIONS: 3648,
    },
    {
        id: "5e807dc6-0b94-4e85-91c1-5d363bb9e908",
        content: "Form Openned : FrmPaidBills",
        numberOfRepetitions: "10,497",
        countByBusiness: "355",
        COUNTBYBUSINESS: 355,
        NUMBEROFREPETITIONS: 10497,
    },
    {
        id: "d3021cf7-5cd8-47ac-87d6-8ec6372d4efd",
        content: "Form Closed : FrmPaidBills",
        numberOfRepetitions: "9,380",
        countByBusiness: "351",
        COUNTBYBUSINESS: 351,
        NUMBEROFREPETITIONS: 9380,
    },
    {
        id: "647b9750-0961-4c22-a91f-036e2c520dfa",
        content: "Form Closed : frmAddCity",
        numberOfRepetitions: "1,583",
        countByBusiness: "349",
        COUNTBYBUSINESS: 349,
        NUMBEROFREPETITIONS: 1583,
    },
    {
        id: "06b03fa5-4db1-430b-b22d-88e01f7004be",
        content: "Form Openned : frmAddCity",
        numberOfRepetitions: "1,582",
        countByBusiness: "349",
        COUNTBYBUSINESS: 349,
        NUMBEROFREPETITIONS: 1582,
    },
    {
        id: "cb4eb7dd-9d0f-43ee-8522-8e8a534b40dc",
        content: "Form Closed : frmPerStor",
        numberOfRepetitions: "2,969",
        countByBusiness: "338",
        COUNTBYBUSINESS: 338,
        NUMBEROFREPETITIONS: 2969,
    },
    {
        id: "b447815a-da22-4667-8d05-a055820e1067",
        content: "Form Openned : frmPerStor",
        numberOfRepetitions: "2,974",
        countByBusiness: "337",
        COUNTBYBUSINESS: 337,
        NUMBEROFREPETITIONS: 2974,
    },
    {
        id: "fb10e5e0-af4c-40ed-9961-80870bd2cf9e",
        content: "Form Openned : FrmExpenses",
        numberOfRepetitions: "1,398",
        countByBusiness: "326",
        COUNTBYBUSINESS: 326,
        NUMBEROFREPETITIONS: 1398,
    },
    {
        id: "1966141f-2336-492f-8afc-a79f09ee56e3",
        content: "Form Openned : frmReceipt2C",
        numberOfRepetitions: "8,055",
        countByBusiness: "319",
        COUNTBYBUSINESS: 319,
        NUMBEROFREPETITIONS: 8055,
    },
    {
        id: "8ae45323-1829-4184-86cd-dba7c1cf6c45",
        content: "Form Openned : FrmCurrencies",
        numberOfRepetitions: "60,629",
        countByBusiness: "317",
        COUNTBYBUSINESS: 317,
        NUMBEROFREPETITIONS: 60629,
    },
    {
        id: "0320352d-e0c9-4385-98cd-1dbf76bf8b79",
        content: "Form Closed : FrmExpenses",
        numberOfRepetitions: "1,349",
        countByBusiness: "317",
        COUNTBYBUSINESS: 317,
        NUMBEROFREPETITIONS: 1349,
    },
    {
        id: "e6e51055-b47a-434d-8185-049b538aa1d7",
        content: "Form Closed : frmReceipt2C",
        numberOfRepetitions: "7,830",
        countByBusiness: "316",
        COUNTBYBUSINESS: 316,
        NUMBEROFREPETITIONS: 7830,
    },
    {
        id: "26b14936-dd77-482e-83f3-977a2f266224",
        content: "Form Openned : FrmSalesStatements",
        numberOfRepetitions: "2,045",
        countByBusiness: "299",
        COUNTBYBUSINESS: 299,
        NUMBEROFREPETITIONS: 2045,
    },
    {
        id: "2398fd6a-5f81-4444-ad3f-7e0454b15fd2",
        content: "Form Closed : FrmSalesStatements",
        numberOfRepetitions: "1,917",
        countByBusiness: "298",
        COUNTBYBUSINESS: 298,
        NUMBEROFREPETITIONS: 1917,
    },
    {
        id: "eb47d935-fc4b-402b-bb4f-cf1e91845114",
        content: "Form Openned : FrmActualvalue",
        numberOfRepetitions: "9,449",
        countByBusiness: "288",
        COUNTBYBUSINESS: 288,
        NUMBEROFREPETITIONS: 9449,
    },
    {
        id: "66fe16cc-6a61-470a-a8ae-9cf03ce040c3",
        content: "Form Openned : BalanceTransferBtoB",
        numberOfRepetitions: "21,400",
        countByBusiness: "284",
        COUNTBYBUSINESS: 284,
        NUMBEROFREPETITIONS: 21400,
    },
    {
        id: "4ba7f785-56ca-408e-9f02-a5d2f1416dc4",
        content: "Form Closed : BalanceTransferBtoB",
        numberOfRepetitions: "19,902",
        countByBusiness: "282",
        COUNTBYBUSINESS: 282,
        NUMBEROFREPETITIONS: 19902,
    },
    {
        id: "900c9688-050d-4077-ada3-fddde6dab8a2",
        content: "Form Closed : FrmActualvalue",
        numberOfRepetitions: "9,173",
        countByBusiness: "281",
        COUNTBYBUSINESS: 281,
        NUMBEROFREPETITIONS: 9173,
    },
    {
        id: "c1fa2a4f-72b5-4031-9909-e944ffd3c932",
        content: "Form Openned : BalanceTransferToTreasury",
        numberOfRepetitions: "19,385",
        countByBusiness: "278",
        COUNTBYBUSINESS: 278,
        NUMBEROFREPETITIONS: 19385,
    },
    {
        id: "8f84b65e-1e40-459b-9dee-c342df07625d",
        content: "Form Closed : BalanceTransferToTreasury",
        numberOfRepetitions: "18,551",
        countByBusiness: "275",
        COUNTBYBUSINESS: 275,
        NUMBEROFREPETITIONS: 18551,
    },
    {
        id: "5396e950-048d-4219-9c90-2f408287ec68",
        content: "Form Openned : frmTransfer",
        numberOfRepetitions: "5,930",
        countByBusiness: "270",
        COUNTBYBUSINESS: 270,
        NUMBEROFREPETITIONS: 5930,
    },
    {
        id: "cc3dea1a-a9e9-4614-9dff-0805622a0b3a",
        content: "Form Openned : FrmUpdater",
        numberOfRepetitions: "875",
        countByBusiness: "269",
        COUNTBYBUSINESS: 269,
        NUMBEROFREPETITIONS: 875,
    },
    {
        id: "271cfdb5-f21e-4959-a92a-a1d8e6bdabc5",
        content: "Form Closed : frmTransfer",
        numberOfRepetitions: "5,808",
        countByBusiness: "264",
        COUNTBYBUSINESS: 264,
        NUMBEROFREPETITIONS: 5808,
    },
    {
        id: "96cf7392-fc58-4310-a198-00bbc7183b85",
        content: "Form Openned : FrmCustomerProfit",
        numberOfRepetitions: "4,472",
        countByBusiness: "260",
        COUNTBYBUSINESS: 260,
        NUMBEROFREPETITIONS: 4472,
    },
    {
        id: "778bccfa-0bbd-4bf1-8eab-d096da217568",
        content: "Form Closed : FrmUpdater",
        numberOfRepetitions: "786",
        countByBusiness: "259",
        COUNTBYBUSINESS: 259,
        NUMBEROFREPETITIONS: 786,
    },
    {
        id: "6826bba3-9699-47bf-a072-e1b43105ec86",
        content: "Form Openned : FrmWinandLoss",
        numberOfRepetitions: "1,141",
        countByBusiness: "256",
        COUNTBYBUSINESS: 256,
        NUMBEROFREPETITIONS: 1141,
    },
    {
        id: "b5f9a314-8eec-4bd4-8682-128bdc105f14",
        content: "Form Closed : FrmCustomerProfit",
        numberOfRepetitions: "4,412",
        countByBusiness: "255",
        COUNTBYBUSINESS: 255,
        NUMBEROFREPETITIONS: 4412,
    },
    {
        id: "b8573ecb-19c5-459a-8fef-a4f06b5b772e",
        content: "Form Openned : frmPay2C",
        numberOfRepetitions: "2,436",
        countByBusiness: "253",
        COUNTBYBUSINESS: 253,
        NUMBEROFREPETITIONS: 2436,
    },
    {
        id: "e9bcb023-8a8d-42d8-9c99-90da56ff57a7",
        content: "Form Closed : FrmWinandLoss",
        numberOfRepetitions: "1,124",
        countByBusiness: "253",
        COUNTBYBUSINESS: 253,
        NUMBEROFREPETITIONS: 1124,
    },
    {
        id: "18e8852a-00af-4918-a18e-0dbf20c7054c",
        content: "Form Closed : frmPay2C",
        numberOfRepetitions: "2,271",
        countByBusiness: "249",
        COUNTBYBUSINESS: 249,
        NUMBEROFREPETITIONS: 2271,
    },
    {
        id: "ed432fca-0c9a-4f14-b8f6-0f863e505ac6",
        content: "Form Openned : FrmAccountsMoneyMovements",
        numberOfRepetitions: "716",
        countByBusiness: "247",
        COUNTBYBUSINESS: 247,
        NUMBEROFREPETITIONS: 716,
    },
    {
        id: "3c8b56c3-68db-4cb0-995c-735e9d160a2a",
        content: "Form Openned : FrmAccountingRecord",
        numberOfRepetitions: "7,295",
        countByBusiness: "244",
        COUNTBYBUSINESS: 244,
        NUMBEROFREPETITIONS: 7295,
    },
    {
        id: "9b2a33e7-726a-4417-b11d-b61bdbf52ded",
        content: "Form Closed : FrmAccountsMoneyMovements",
        numberOfRepetitions: "710",
        countByBusiness: "244",
        COUNTBYBUSINESS: 244,
        NUMBEROFREPETITIONS: 710,
    },
    {
        id: "acbce484-cf38-479f-bd02-3412d153fa00",
        content: "Form Closed : FrmAccountingRecord",
        numberOfRepetitions: "7,021",
        countByBusiness: "240",
        COUNTBYBUSINESS: 240,
        NUMBEROFREPETITIONS: 7021,
    },
    {
        id: "a3cb6a1b-7641-44d3-a1ce-4a468ed43177",
        content: "Form Openned : FrmRunningOperations",
        numberOfRepetitions: "748",
        countByBusiness: "238",
        COUNTBYBUSINESS: 238,
        NUMBEROFREPETITIONS: 748,
    },
    {
        id: "a984bd32-37b7-4336-97f1-d0cead139de6",
        content: "Form Closed : FrmRunningOperations",
        numberOfRepetitions: "724",
        countByBusiness: "236",
        COUNTBYBUSINESS: 236,
        NUMBEROFREPETITIONS: 724,
    },
    {
        id: "59e6b4ec-b2fb-4687-8ac8-84133bd2c8f8",
        content: "Form Openned : FrmIncomeStatementReport",
        numberOfRepetitions: "1,238",
        countByBusiness: "233",
        COUNTBYBUSINESS: 233,
        NUMBEROFREPETITIONS: 1238,
    },
    {
        id: "58fc1929-004b-4a9f-b3e0-c7ab610f13d1",
        content: "Form Closed : FrmIncomeStatementReport",
        numberOfRepetitions: "1,166",
        countByBusiness: "225",
        COUNTBYBUSINESS: 225,
        NUMBEROFREPETITIONS: 1166,
    },
    {
        id: "ca716540-3301-468e-abb1-ce360a821fb6",
        content: "Form Openned : FrmItemsEdit",
        numberOfRepetitions: "633",
        countByBusiness: "225",
        COUNTBYBUSINESS: 225,
        NUMBEROFREPETITIONS: 633,
    },
    {
        id: "26845e3b-44b7-4b38-9346-665c16006d1a",
        content: "Form Openned : FrmReceivables",
        numberOfRepetitions: "674",
        countByBusiness: "221",
        COUNTBYBUSINESS: 221,
        NUMBEROFREPETITIONS: 674,
    },
    {
        id: "4f2f8f83-eae4-4e8b-9130-23b719530006",
        content: "Form Closed : FrmItemsEdit",
        numberOfRepetitions: "605",
        countByBusiness: "219",
        COUNTBYBUSINESS: 219,
        NUMBEROFREPETITIONS: 605,
    },
    {
        id: "0d197b9b-4634-4640-b0fd-a19b88d3c29b",
        content: "Form Closed : FrmReceivables",
        numberOfRepetitions: "643",
        countByBusiness: "217",
        COUNTBYBUSINESS: 217,
        NUMBEROFREPETITIONS: 643,
    },
    {
        id: "fa85a485-5a90-4c0c-9c29-bf70f5629d66",
        content: "Form Openned : FrmItemsMovments",
        numberOfRepetitions: "1,035",
        countByBusiness: "205",
        COUNTBYBUSINESS: 205,
        NUMBEROFREPETITIONS: 1035,
    },
    {
        id: "8cbc30ac-8b8d-4aec-b6e2-1fc351464aa5",
        content: "Form Openned : BalanceTransferToBoxs",
        numberOfRepetitions: "4,896",
        countByBusiness: "203",
        COUNTBYBUSINESS: 203,
        NUMBEROFREPETITIONS: 4896,
    },
    {
        id: "21c7bcc8-04cc-475d-b965-f8cda7184b33",
        content: "Form Closed : BalanceTransferToBoxs",
        numberOfRepetitions: "4,661",
        countByBusiness: "203",
        COUNTBYBUSINESS: 203,
        NUMBEROFREPETITIONS: 4661,
    },
    {
        id: "91be8754-2440-4fb8-9388-33a7690d75f7",
        content: "Form Closed : FrmItemsMovments",
        numberOfRepetitions: "956",
        countByBusiness: "201",
        COUNTBYBUSINESS: 201,
        NUMBEROFREPETITIONS: 956,
    },
    {
        id: "373f10d1-06fa-48c4-89de-e3e97b5d390a",
        content: "Form Openned : السندات المحذوفة",
        numberOfRepetitions: "542",
        countByBusiness: "199",
        COUNTBYBUSINESS: 199,
        NUMBEROFREPETITIONS: 542,
    },
    {
        id: "b6c7231e-6d01-4329-bfe9-1a034b9474dc",
        content: "Form Closed : السندات المحذوفة",
        numberOfRepetitions: "532",
        countByBusiness: "198",
        COUNTBYBUSINESS: 198,
        NUMBEROFREPETITIONS: 532,
    },
    {
        id: "49142273-539f-4bcd-90db-29d4a470f017",
        content: "Form Openned : FrmSelectStore",
        numberOfRepetitions: "8,129",
        countByBusiness: "197",
        COUNTBYBUSINESS: 197,
        NUMBEROFREPETITIONS: 8129,
    },
    {
        id: "35dd67db-bf01-40dc-8391-1c0d49a41845",
        content: "Form Closed : FrmSelectStore",
        numberOfRepetitions: "8,125",
        countByBusiness: "197",
        COUNTBYBUSINESS: 197,
        NUMBEROFREPETITIONS: 8125,
    },
    {
        id: "891dbfd9-7d6b-4acf-9a2d-d65667d7034f",
        content: "Form Openned : FrmPremiumPay",
        numberOfRepetitions: "31,091",
        countByBusiness: "194",
        COUNTBYBUSINESS: 194,
        NUMBEROFREPETITIONS: 31091,
    },
    {
        id: "edb048a6-9e56-4a4d-b2c8-7cd81c2e1e4d",
        content: "Form Closed : FrmPremiumPay",
        numberOfRepetitions: "28,744",
        countByBusiness: "192",
        COUNTBYBUSINESS: 192,
        NUMBEROFREPETITIONS: 28744,
    },
    {
        id: "f2f0627f-3e06-47ff-ba27-33ee5a740ac7",
        content: "Form Openned : FrmShowSales",
        numberOfRepetitions: "2,881",
        countByBusiness: "192",
        COUNTBYBUSINESS: 192,
        NUMBEROFREPETITIONS: 2881,
    },
    {
        id: "04f95853-8296-47ec-a120-f322763d0ce6",
        content: "Form Closed : FrmShowSales",
        numberOfRepetitions: "2,724",
        countByBusiness: "191",
        COUNTBYBUSINESS: 191,
        NUMBEROFREPETITIONS: 2724,
    },
    {
        id: "fa442f63-5b5e-4f0e-a4cd-e93ec9ac2a8f",
        content: "Form Openned : FrmLinkProvidersWithCompanies",
        numberOfRepetitions: "459",
        countByBusiness: "183",
        COUNTBYBUSINESS: 183,
        NUMBEROFREPETITIONS: 459,
    },
    {
        id: "e342aa4a-95c3-400d-8e2f-87adc78841a2",
        content: "Form Closed : FrmLinkProvidersWithCompanies",
        numberOfRepetitions: "459",
        countByBusiness: "183",
        COUNTBYBUSINESS: 183,
        NUMBEROFREPETITIONS: 459,
    },
    {
        id: "9ebaef13-ab5b-4c6f-be62-fb02f836b4a7",
        content: "Form Openned : FrmCustomersMovement",
        numberOfRepetitions: "453",
        countByBusiness: "177",
        COUNTBYBUSINESS: 177,
        NUMBEROFREPETITIONS: 453,
    },
    {
        id: "059a1016-a018-4ac0-bac7-d8e32dd77707",
        content: "Form Openned : FrmDeleteTransfer",
        numberOfRepetitions: "1,165",
        countByBusiness: "176",
        COUNTBYBUSINESS: 176,
        NUMBEROFREPETITIONS: 1165,
    },
    {
        id: "6b870dc1-2be4-424d-a851-4c9715b5f6b4",
        content: "Form Closed : FrmDeleteTransfer",
        numberOfRepetitions: "1,161",
        countByBusiness: "176",
        COUNTBYBUSINESS: 176,
        NUMBEROFREPETITIONS: 1161,
    },
    {
        id: "6bddc7cb-2ba0-4ab5-a98d-2198ade637f5",
        content: "Form Closed : FrmCustomersMovement",
        numberOfRepetitions: "443",
        countByBusiness: "175",
        COUNTBYBUSINESS: 175,
        NUMBEROFREPETITIONS: 443,
    },
    {
        id: "d8852d37-d297-44d1-a63b-c68aaa95e456",
        content: "Form Openned : frmFillings",
        numberOfRepetitions: "751",
        countByBusiness: "174",
        COUNTBYBUSINESS: 174,
        NUMBEROFREPETITIONS: 751,
    },
    {
        id: "ef5a957e-20c8-4fca-a96b-a7c133317ed1",
        content: "Form Closed : frmFillings",
        numberOfRepetitions: "711",
        countByBusiness: "173",
        COUNTBYBUSINESS: 173,
        NUMBEROFREPETITIONS: 711,
    },
    {
        id: "2057d23f-9a64-495a-ae1c-a86751c2738c",
        content: "Form Openned : FrmItemLoser",
        numberOfRepetitions: "491",
        countByBusiness: "172",
        COUNTBYBUSINESS: 172,
        NUMBEROFREPETITIONS: 491,
    },
    {
        id: "0fecc24d-0734-47b1-b918-c639ffe2c02c",
        content: "Form Closed : FrmItemLoser",
        numberOfRepetitions: "486",
        countByBusiness: "171",
        COUNTBYBUSINESS: 171,
        NUMBEROFREPETITIONS: 486,
    },
    {
        id: "378c4956-8576-44b1-9a52-52fac024c9d2",
        content: "Form Openned : FrmOnlineOrders",
        numberOfRepetitions: "1,607",
        countByBusiness: "170",
        COUNTBYBUSINESS: 170,
        NUMBEROFREPETITIONS: 1607,
    },
    {
        id: "8b99681d-572d-4983-9e13-9e7788460e0e",
        content: "Form Openned : FrmEditBalanceReport",
        numberOfRepetitions: "466",
        countByBusiness: "170",
        COUNTBYBUSINESS: 170,
        NUMBEROFREPETITIONS: 466,
    },
    {
        id: "564adcf2-63f2-4958-a8b9-68e718dc799b",
        content: "Form Closed : FrmEditBalanceReport",
        numberOfRepetitions: "457",
        countByBusiness: "168",
        COUNTBYBUSINESS: 168,
        NUMBEROFREPETITIONS: 457,
    },
    {
        id: "f0d55ef4-1914-486c-94d9-eed294ab7dd6",
        content: "Form Closed : FrmOnlineOrders",
        numberOfRepetitions: "1,123",
        countByBusiness: "167",
        COUNTBYBUSINESS: 167,
        NUMBEROFREPETITIONS: 1123,
    },
    {
        id: "859843cb-a79c-4b8a-8e68-68316f3b20e7",
        content: "Form Openned : FrmImportItemsFromExcel",
        numberOfRepetitions: "503",
        countByBusiness: "151",
        COUNTBYBUSINESS: 151,
        NUMBEROFREPETITIONS: 503,
    },
    {
        id: "38c7d59b-88ea-425c-98ce-dc0da3aebf72",
        content: "Form Openned : Frm_Insert_Premium",
        numberOfRepetitions: "26,452",
        countByBusiness: "149",
        COUNTBYBUSINESS: 149,
        NUMBEROFREPETITIONS: 26452,
    },
    {
        id: "c7c239f0-99e1-469f-9d2e-55e6b2e48fa6",
        content: "Form Closed : Frm_Insert_Premium",
        numberOfRepetitions: "25,824",
        countByBusiness: "147",
        COUNTBYBUSINESS: 147,
        NUMBEROFREPETITIONS: 25824,
    },
    {
        id: "58da597f-7788-47b2-8cc4-4eab68917ac6",
        content: "Form Closed : FrmImportItemsFromExcel",
        numberOfRepetitions: "417",
        countByBusiness: "143",
        COUNTBYBUSINESS: 143,
        NUMBEROFREPETITIONS: 417,
    },
    {
        id: "35ebc729-da2c-4314-8b0f-20c494232f4c",
        content: "Form Openned : FrmViewBonds",
        numberOfRepetitions: "366",
        countByBusiness: "137",
        COUNTBYBUSINESS: 137,
        NUMBEROFREPETITIONS: 366,
    },
    {
        id: "a92a9302-0b66-4cff-8b58-4c06e7333951",
        content: "Form Openned : FrmChoseBusiness",
        numberOfRepetitions: "270",
        countByBusiness: "134",
        COUNTBYBUSINESS: 134,
        NUMBEROFREPETITIONS: 270,
    },
    {
        id: "50ccce8a-3b78-4dc8-8791-7d2ba5aad488",
        content: "Form Closed : FrmChoseBusiness",
        numberOfRepetitions: "268",
        countByBusiness: "134",
        COUNTBYBUSINESS: 134,
        NUMBEROFREPETITIONS: 268,
    },
    {
        id: "2b2d1c6c-8c78-49e5-9ff5-83386b219b79",
        content: "Form Closed : FrmViewBonds",
        numberOfRepetitions: "355",
        countByBusiness: "133",
        COUNTBYBUSINESS: 133,
        NUMBEROFREPETITIONS: 355,
    },
    {
        id: "2b6ce14b-43e7-43a9-a502-825cb94c3624",
        content: "Form Openned : FrmItemTargets",
        numberOfRepetitions: "314",
        countByBusiness: "132",
        COUNTBYBUSINESS: 132,
        NUMBEROFREPETITIONS: 314,
    },
    {
        id: "e2d5cb66-920f-4ab1-93be-62be8fa9b952",
        content: "Form Closed : FrmItemTargets",
        numberOfRepetitions: "298",
        countByBusiness: "131",
        COUNTBYBUSINESS: 131,
        NUMBEROFREPETITIONS: 298,
    },
    {
        id: "830fd247-cc33-4a79-ba11-6240f821ae6a",
        content: "Form Openned : FrmStoreTransformReport",
        numberOfRepetitions: "1,518",
        countByBusiness: "130",
        COUNTBYBUSINESS: 130,
        NUMBEROFREPETITIONS: 1518,
    },
    {
        id: "62a716d0-013e-4a6a-aeac-90d523147937",
        content: "Form Closed : FrmStoreTransformReport",
        numberOfRepetitions: "1,467",
        countByBusiness: "130",
        COUNTBYBUSINESS: 130,
        NUMBEROFREPETITIONS: 1467,
    },
    {
        id: "688e771d-4e40-4d5b-a4c5-48707ec95c2a",
        content: "Form Openned : FrmPremiumsDue",
        numberOfRepetitions: "4,029",
        countByBusiness: "126",
        COUNTBYBUSINESS: 126,
        NUMBEROFREPETITIONS: 4029,
    },
    {
        id: "26d29cd5-f5c3-49d3-a60f-f44eabfa955b",
        content: "Form Closed : FrmPremiumsDue",
        numberOfRepetitions: "3,781",
        countByBusiness: "124",
        COUNTBYBUSINESS: 124,
        NUMBEROFREPETITIONS: 3781,
    },
    {
        id: "8ec35884-0e44-4f43-a3c3-c449a3a25402",
        content: "Form Openned : FrmMultiBarcodePrint",
        numberOfRepetitions: "510",
        countByBusiness: "117",
        COUNTBYBUSINESS: 117,
        NUMBEROFREPETITIONS: 510,
    },
    {
        id: "91cdb46e-1e96-48fe-bbc3-d239c00055ef",
        content: "Form Openned : FrmCheckerSupplier",
        numberOfRepetitions: "277",
        countByBusiness: "115",
        COUNTBYBUSINESS: 115,
        NUMBEROFREPETITIONS: 277,
    },
    {
        id: "2c4965b7-198a-47ba-8ac2-c4e49c069c6b",
        content: "Form Closed : FrmCheckerSupplier",
        numberOfRepetitions: "272",
        countByBusiness: "115",
        COUNTBYBUSINESS: 115,
        NUMBEROFREPETITIONS: 272,
    },
    {
        id: "ab97149d-458b-42f4-9fbe-2e7338f0d78e",
        content: "Form Closed : FrmMultiBarcodePrint",
        numberOfRepetitions: "463",
        countByBusiness: "109",
        COUNTBYBUSINESS: 109,
        NUMBEROFREPETITIONS: 463,
    },
    {
        id: "d0f801d4-a59d-4897-86b1-99d79e5324b7",
        content: "Form Openned : FrmDistributorBills",
        numberOfRepetitions: "9,138",
        countByBusiness: "108",
        COUNTBYBUSINESS: 108,
        NUMBEROFREPETITIONS: 9138,
    },
    {
        id: "0090f0a1-4d39-45d7-8f76-7b396f4c2601",
        content: "Form Openned : FrmUnsoldItems",
        numberOfRepetitions: "280",
        countByBusiness: "108",
        COUNTBYBUSINESS: 108,
        NUMBEROFREPETITIONS: 280,
    },
    {
        id: "df74ded5-0524-47cb-9cd3-aec794d119aa",
        content: "Form Closed : FrmUnsoldItems",
        numberOfRepetitions: "270",
        countByBusiness: "108",
        COUNTBYBUSINESS: 108,
        NUMBEROFREPETITIONS: 270,
    },
    {
        id: "343cb4c7-7f20-4159-916f-908fd2df5aec",
        content: "Form Openned : PremiumPaystate",
        numberOfRepetitions: "4,316",
        countByBusiness: "105",
        COUNTBYBUSINESS: 105,
        NUMBEROFREPETITIONS: 4316,
    },
    {
        id: "fcfb532a-79b6-449c-8457-a5284425cd72",
        content: "Form Closed : FrmDistributorBills",
        numberOfRepetitions: "7,703",
        countByBusiness: "104",
        COUNTBYBUSINESS: 104,
        NUMBEROFREPETITIONS: 7703,
    },
    {
        id: "893bd72a-446d-44f1-add8-ec123f2e284a",
        content: "Form Closed : PremiumPaystate",
        numberOfRepetitions: "4,286",
        countByBusiness: "104",
        COUNTBYBUSINESS: 104,
        NUMBEROFREPETITIONS: 4286,
    },
    {
        id: "b173ff46-1e9f-40bd-8b2d-3359e94c243b",
        content: "Form Openned : FrmPremiumState",
        numberOfRepetitions: "1,687",
        countByBusiness: "103",
        COUNTBYBUSINESS: 103,
        NUMBEROFREPETITIONS: 1687,
    },
    {
        id: "48845e3f-a158-4bb0-b303-cdbc16a795ce",
        content: "Form Closed : FrmPremiumState",
        numberOfRepetitions: "1,625",
        countByBusiness: "103",
        COUNTBYBUSINESS: 103,
        NUMBEROFREPETITIONS: 1625,
    },
    {
        id: "e565b025-df94-4e11-833c-c4b0b4dabcf1",
        content: "Form Openned : FrmCheckerSupplierReport",
        numberOfRepetitions: "379",
        countByBusiness: "103",
        COUNTBYBUSINESS: 103,
        NUMBEROFREPETITIONS: 379,
    },
    {
        id: "37ec9c85-9cee-454f-824b-7519bc94e522",
        content: "Form Openned : FrmMorabaaServices",
        numberOfRepetitions: "256",
        countByBusiness: "101",
        COUNTBYBUSINESS: 101,
        NUMBEROFREPETITIONS: 256,
    },
    {
        id: "eb4b0861-9520-4ac4-8e9c-614ceb5de5d1",
        content: "Form Closed : FrmCheckerSupplierReport",
        numberOfRepetitions: "348",
        countByBusiness: "99",
        COUNTBYBUSINESS: 99,
        NUMBEROFREPETITIONS: 348,
    },
    {
        id: "67f8f623-4c67-43fd-b5aa-371168969cfe",
        content: "Form Openned : frm_Profit_Distribution",
        numberOfRepetitions: "220",
        countByBusiness: "98",
        COUNTBYBUSINESS: 98,
        NUMBEROFREPETITIONS: 220,
    },
    {
        id: "27c38cac-a165-4031-8eb8-42c2dcab59b4",
        content: "Form Closed : frm_Profit_Distribution",
        numberOfRepetitions: "216",
        countByBusiness: "97",
        COUNTBYBUSINESS: 97,
        NUMBEROFREPETITIONS: 216,
    },
    {
        id: "b4d6e7b5-edbf-431e-bd0e-071f8c2dd0f3",
        content: "Form Openned : تقرير سندات الحوالة ",
        numberOfRepetitions: "267",
        countByBusiness: "92",
        COUNTBYBUSINESS: 92,
        NUMBEROFREPETITIONS: 267,
    },
    {
        id: "eaa8613f-0505-4270-b56f-b6322c0a862f",
        content: "Form Closed : تقرير سندات الحوالة ",
        numberOfRepetitions: "259",
        countByBusiness: "92",
        COUNTBYBUSINESS: 92,
        NUMBEROFREPETITIONS: 259,
    },
    {
        id: "ed5f07cf-56a2-470b-bdf9-53807f70fbaa",
        content: "Form Openned : FrmGiftsItems",
        numberOfRepetitions: "353",
        countByBusiness: "91",
        COUNTBYBUSINESS: 91,
        NUMBEROFREPETITIONS: 353,
    },
    {
        id: "91eca467-38e0-425a-9069-050fd52cbc56",
        content: "Form Openned : FrmUserVisibilty",
        numberOfRepetitions: "293",
        countByBusiness: "91",
        COUNTBYBUSINESS: 91,
        NUMBEROFREPETITIONS: 293,
    },
    {
        id: "324ccda2-27e8-492c-ace5-b0d1019bf220",
        content: "Form Closed : FrmUserVisibilty",
        numberOfRepetitions: "293",
        countByBusiness: "91",
        COUNTBYBUSINESS: 91,
        NUMBEROFREPETITIONS: 293,
    },
    {
        id: "acb142ca-566c-4108-b900-bde4f1784c78",
        content: "Form Closed : FrmMorabaaServices",
        numberOfRepetitions: "227",
        countByBusiness: "91",
        COUNTBYBUSINESS: 91,
        NUMBEROFREPETITIONS: 227,
    },
    {
        id: "60a160b3-d6a1-4e22-9e34-44a012e6d9be",
        content: "Form Closed : FrmGiftsItems",
        numberOfRepetitions: "344",
        countByBusiness: "88",
        COUNTBYBUSINESS: 88,
        NUMBEROFREPETITIONS: 344,
    },
    {
        id: "4ae4849f-074c-4caa-8fde-09d4f40d619b",
        content: "Form Openned : FrmDistributorsVsItems",
        numberOfRepetitions: "668",
        countByBusiness: "82",
        COUNTBYBUSINESS: 82,
        NUMBEROFREPETITIONS: 668,
    },
    {
        id: "6fa2a504-0d8d-4534-a004-fc92bc71d01e",
        content: "Form Closed : FrmDistributorsVsItems",
        numberOfRepetitions: "637",
        countByBusiness: "81",
        COUNTBYBUSINESS: 81,
        NUMBEROFREPETITIONS: 637,
    },
    {
        id: "46314809-1c63-46e4-8a28-52ee6df0a8f2",
        content: "Form Openned : FrmLinkDistributers",
        numberOfRepetitions: "554",
        countByBusiness: "77",
        COUNTBYBUSINESS: 77,
        NUMBEROFREPETITIONS: 554,
    },
    {
        id: "3b844674-4cc4-4653-817f-a384ffe54382",
        content: "Form Closed : FrmLinkDistributers",
        numberOfRepetitions: "526",
        countByBusiness: "76",
        COUNTBYBUSINESS: 76,
        NUMBEROFREPETITIONS: 526,
    },
    {
        id: "4114cae5-a349-494c-bb6b-83af75b7581e",
        content: "Form Openned : frmItemQDetails",
        numberOfRepetitions: "24,758",
        countByBusiness: "73",
        COUNTBYBUSINESS: 73,
        NUMBEROFREPETITIONS: 24758,
    },
    {
        id: "c55b9b9f-9065-43ef-b915-4baefba4076c",
        content: "Form Closed : frmItemQDetails",
        numberOfRepetitions: "24,750",
        countByBusiness: "73",
        COUNTBYBUSINESS: 73,
        NUMBEROFREPETITIONS: 24750,
    },
    {
        id: "03297da5-b588-441c-b60c-30d35c0c7773",
        content: "Form Openned : frmPaidBillsByDistributer",
        numberOfRepetitions: "478",
        countByBusiness: "69",
        COUNTBYBUSINESS: 69,
        NUMBEROFREPETITIONS: 478,
    },
    {
        id: "6f213580-dfed-4e6a-b3c7-ee095be12413",
        content: "Form Openned : FrmItemsRequestReport",
        numberOfRepetitions: "182",
        countByBusiness: "69",
        COUNTBYBUSINESS: 69,
        NUMBEROFREPETITIONS: 182,
    },
    {
        id: "edccc108-6e46-4c13-9a34-8a49b46e8a5a",
        content: "Form Closed : FrmItemsRequestReport",
        numberOfRepetitions: "178",
        countByBusiness: "68",
        COUNTBYBUSINESS: 68,
        NUMBEROFREPETITIONS: 178,
    },
    {
        id: "9231c24c-f927-48ba-b4a6-d47e96291460",
        content: "Form Closed : frmPaidBillsByDistributer",
        numberOfRepetitions: "439",
        countByBusiness: "64",
        COUNTBYBUSINESS: 64,
        NUMBEROFREPETITIONS: 439,
    },
    {
        id: "47281f0c-323f-4b0a-9b95-8050cb9c10a4",
        content: "Form Openned : FrmMixedBond",
        numberOfRepetitions: "127",
        countByBusiness: "56",
        COUNTBYBUSINESS: 56,
        NUMBEROFREPETITIONS: 127,
    },
    {
        id: "abb7eb72-6dd8-4c33-bbcd-0288e9360d52",
        content: "Form Openned : FrmOnlineBonds",
        numberOfRepetitions: "2,044",
        countByBusiness: "55",
        COUNTBYBUSINESS: 55,
        NUMBEROFREPETITIONS: 2044,
    },
    {
        id: "2f47f64e-0bdd-4dc5-84ab-0bdabbca5df7",
        content: "Form Openned : FrmReceiveDeliveryBills",
        numberOfRepetitions: "231",
        countByBusiness: "55",
        COUNTBYBUSINESS: 55,
        NUMBEROFREPETITIONS: 231,
    },
    {
        id: "fc6fcded-8ff6-4592-8285-1a0c70980f52",
        content: "Form Closed : FrmMixedBond",
        numberOfRepetitions: "124",
        countByBusiness: "55",
        COUNTBYBUSINESS: 55,
        NUMBEROFREPETITIONS: 124,
    },
    {
        id: "d3cec615-6473-4190-8a25-852f9f3c2e04",
        content: "Form Openned : frmItemQdetailsForBillItem",
        numberOfRepetitions: "9,299",
        countByBusiness: "53",
        COUNTBYBUSINESS: 53,
        NUMBEROFREPETITIONS: 9299,
    },
    {
        id: "fa8cdd7b-337d-453a-98b2-a9805e9e3d59",
        content: "Form Closed : frmItemQdetailsForBillItem",
        numberOfRepetitions: "9,293",
        countByBusiness: "53",
        COUNTBYBUSINESS: 53,
        NUMBEROFREPETITIONS: 9293,
    },
    {
        id: "ee7c130b-35e8-494f-9f8a-7f39f5eccb18",
        content: "Form Closed : FrmNote",
        numberOfRepetitions: "784",
        countByBusiness: "53",
        COUNTBYBUSINESS: 53,
        NUMBEROFREPETITIONS: 784,
    },
    {
        id: "3cdec13c-7055-45b8-a7cb-c6bcc983a4be",
        content: "Form Openned : FrmNote",
        numberOfRepetitions: "783",
        countByBusiness: "53",
        COUNTBYBUSINESS: 53,
        NUMBEROFREPETITIONS: 783,
    },
    {
        id: "a9eb4313-78ac-4ce0-82b9-e8b3cb3b15f8",
        content: "Form Openned : frmTask",
        numberOfRepetitions: "676",
        countByBusiness: "53",
        COUNTBYBUSINESS: 53,
        NUMBEROFREPETITIONS: 676,
    },
    {
        id: "587ee560-8286-48dd-a3dc-a7e519bdea05",
        content: "Form Closed : frmTask",
        numberOfRepetitions: "676",
        countByBusiness: "53",
        COUNTBYBUSINESS: 53,
        NUMBEROFREPETITIONS: 676,
    },
    {
        id: "68d2e286-33d3-4117-b336-210db67f4caf",
        content: "Form Closed : FrmReceiveDeliveryBills",
        numberOfRepetitions: "222",
        countByBusiness: "53",
        COUNTBYBUSINESS: 53,
        NUMBEROFREPETITIONS: 222,
    },
    {
        id: "b11992bf-2982-4b6f-b985-db35f38eadf4",
        content: "Form Closed : FrmOnlineBonds",
        numberOfRepetitions: "1,873",
        countByBusiness: "52",
        COUNTBYBUSINESS: 52,
        NUMBEROFREPETITIONS: 1873,
    },
    {
        id: "7c282250-9c88-4b30-ba17-c2cacbfeda49",
        content: "Form Openned : frmCalcProfit",
        numberOfRepetitions: "162",
        countByBusiness: "51",
        COUNTBYBUSINESS: 51,
        NUMBEROFREPETITIONS: 162,
    },
    {
        id: "251e3009-0cd1-435b-96c1-f1fbb2e5cde1",
        content: "Form Closed : frmCalcProfit",
        numberOfRepetitions: "162",
        countByBusiness: "51",
        COUNTBYBUSINESS: 51,
        NUMBEROFREPETITIONS: 162,
    },
    {
        id: "5f2bb779-da48-48d8-bff6-ba4388fac369",
        content: "Form Openned : FrmVhagar",
        numberOfRepetitions: "236",
        countByBusiness: "50",
        COUNTBYBUSINESS: 50,
        NUMBEROFREPETITIONS: 236,
    },
    {
        id: "87264266-b804-4505-a545-5e1ef24789ad",
        content: "Form Openned : FrmPremuimsBillsReport",
        numberOfRepetitions: "224",
        countByBusiness: "50",
        COUNTBYBUSINESS: 50,
        NUMBEROFREPETITIONS: 224,
    },
    {
        id: "7687bb76-cb0c-475d-84a9-360251e742f4",
        content: "Form Closed : FrmVhagar",
        numberOfRepetitions: "231",
        countByBusiness: "49",
        COUNTBYBUSINESS: 49,
        NUMBEROFREPETITIONS: 231,
    },
    {
        id: "23208c11-37d6-462e-afda-7c43f18398d2",
        content: "Form Closed : FrmPremuimsBillsReport",
        numberOfRepetitions: "213",
        countByBusiness: "46",
        COUNTBYBUSINESS: 46,
        NUMBEROFREPETITIONS: 213,
    },
    {
        id: "60f3db39-84da-49bb-8264-f2037b7a87c8",
        content: "Form Openned : FrmReservedItemsReport",
        numberOfRepetitions: "113",
        countByBusiness: "44",
        COUNTBYBUSINESS: 44,
        NUMBEROFREPETITIONS: 113,
    },
    {
        id: "d4eef28d-ca38-41c8-95c9-e6b20e5c6af2",
        content: "Form Openned : FrmDistributerPath",
        numberOfRepetitions: "99",
        countByBusiness: "42",
        COUNTBYBUSINESS: 42,
        NUMBEROFREPETITIONS: 99,
    },
    {
        id: "ee637848-7c87-4107-8137-861a88352739",
        content: "Form Closed : FrmDistributerPath",
        numberOfRepetitions: "98",
        countByBusiness: "42",
        COUNTBYBUSINESS: 42,
        NUMBEROFREPETITIONS: 98,
    },
    {
        id: "ce8fc2d6-108b-4a90-b4f3-373bd54ec774",
        content: "Form Closed : FrmReservedItemsReport",
        numberOfRepetitions: "106",
        countByBusiness: "41",
        COUNTBYBUSINESS: 41,
        NUMBEROFREPETITIONS: 106,
    },
    {
        id: "b5ad5557-ff83-4c99-bb78-ee109a92c4c6",
        content: "Form Openned : FrmBarcodeSearch",
        numberOfRepetitions: "420",
        countByBusiness: "40",
        COUNTBYBUSINESS: 40,
        NUMBEROFREPETITIONS: 420,
    },
    {
        id: "5540c2de-7f8f-4980-ba44-c8c92c3193ec",
        content: "Form Closed : FrmBarcodeSearch",
        numberOfRepetitions: "419",
        countByBusiness: "40",
        COUNTBYBUSINESS: 40,
        NUMBEROFREPETITIONS: 419,
    },
    {
        id: "bed2beec-6bf3-4467-9102-37da0ed86fc2",
        content: "Form Openned : FrmJobLocation",
        numberOfRepetitions: "175",
        countByBusiness: "38",
        COUNTBYBUSINESS: 38,
        NUMBEROFREPETITIONS: 175,
    },
    {
        id: "db491f16-587d-48e2-a7fa-1f2125cdbd9f",
        content: "Form Closed : FrmJobLocation",
        numberOfRepetitions: "175",
        countByBusiness: "38",
        COUNTBYBUSINESS: 38,
        NUMBEROFREPETITIONS: 175,
    },
    {
        id: "f0e39503-f997-437a-96fc-539ce5fcef3e",
        content: "Form Openned : FrmNoOrderNote",
        numberOfRepetitions: "122",
        countByBusiness: "38",
        COUNTBYBUSINESS: 38,
        NUMBEROFREPETITIONS: 122,
    },
    {
        id: "f4c713bd-cc76-47ed-b493-a40afdd4ad62",
        content: "Form Closed : FrmNoOrderNote",
        numberOfRepetitions: "118",
        countByBusiness: "37",
        COUNTBYBUSINESS: 37,
        NUMBEROFREPETITIONS: 118,
    },
    {
        id: "8d208e29-4f80-40a5-81d6-dabe8173c183",
        content: "Form Openned : FrmSellAndBuyCurrencies",
        numberOfRepetitions: "13,287",
        countByBusiness: "35",
        COUNTBYBUSINESS: 35,
        NUMBEROFREPETITIONS: 13287,
    },
    {
        id: "323f0b83-c4c9-4af5-a4a1-6ca0733d8c2e",
        content: "Form Closed : FrmSellAndBuyCurrencies",
        numberOfRepetitions: "12,624",
        countByBusiness: "35",
        COUNTBYBUSINESS: 35,
        NUMBEROFREPETITIONS: 12624,
    },
    {
        id: "78513fbc-3d9b-4a58-8bf0-45151ebe7c08",
        content: "Form Openned : FrmPaidBillsDiscountsConditions",
        numberOfRepetitions: "67",
        countByBusiness: "35",
        COUNTBYBUSINESS: 35,
        NUMBEROFREPETITIONS: 67,
    },
    {
        id: "62734c94-4db9-4fda-9393-0a747d7ac15a",
        content: "Form Closed : FrmPaidBillsDiscountsConditions",
        numberOfRepetitions: "67",
        countByBusiness: "35",
        COUNTBYBUSINESS: 35,
        NUMBEROFREPETITIONS: 67,
    },
    {
        id: "3431ad08-fc41-4953-8bfe-2c8cb763b5ba",
        content: "Form Openned : FrmImportAccounts",
        numberOfRepetitions: "94",
        countByBusiness: "32",
        COUNTBYBUSINESS: 32,
        NUMBEROFREPETITIONS: 94,
    },
    {
        id: "8fe08f92-471e-427d-890d-8d633b98281c",
        content: "Form Openned : FrmExchTransfer",
        numberOfRepetitions: "10,234",
        countByBusiness: "31",
        COUNTBYBUSINESS: 31,
        NUMBEROFREPETITIONS: 10234,
    },
    {
        id: "95aeabb3-e343-403b-a533-9dd0da62d90f",
        content: "Form Closed : FrmExchTransfer",
        numberOfRepetitions: "9,421",
        countByBusiness: "31",
        COUNTBYBUSINESS: 31,
        NUMBEROFREPETITIONS: 9421,
    },
    {
        id: "57082643-495a-4c4b-9900-386b54fad4c0",
        content: "Form Openned : FrmViewTransfer",
        numberOfRepetitions: "10,455",
        countByBusiness: "30",
        COUNTBYBUSINESS: 30,
        NUMBEROFREPETITIONS: 10455,
    },
    {
        id: "fd810d1d-5311-4a50-8792-45c1feed96b7",
        content: "Form Closed : FrmViewTransfer",
        numberOfRepetitions: "10,429",
        countByBusiness: "30",
        COUNTBYBUSINESS: 30,
        NUMBEROFREPETITIONS: 10429,
    },
    {
        id: "2e8a3133-b19d-4960-a248-71afc5196340",
        content: "Form Closed : FrmImportAccounts",
        numberOfRepetitions: "82",
        countByBusiness: "30",
        COUNTBYBUSINESS: 30,
        NUMBEROFREPETITIONS: 82,
    },
    {
        id: "8e3e8aa5-45d6-4a75-b84f-7038be137192",
        content: "Form Openned : FrmTemplate",
        numberOfRepetitions: "9,476",
        countByBusiness: "29",
        COUNTBYBUSINESS: 29,
        NUMBEROFREPETITIONS: 9476,
    },
    {
        id: "baa54e5c-3ef7-45a6-9eb1-256029363d4d",
        content: "Form Closed : FrmViewQRImage",
        numberOfRepetitions: "106",
        countByBusiness: "29",
        COUNTBYBUSINESS: 29,
        NUMBEROFREPETITIONS: 106,
    },
    {
        id: "b3a2848f-3217-4b93-bf1c-be9daa2666a3",
        content: "Form Closed : FrmTemplate",
        numberOfRepetitions: "9,463",
        countByBusiness: "28",
        COUNTBYBUSINESS: 28,
        NUMBEROFREPETITIONS: 9463,
    },
    {
        id: "868bf41a-7531-4f34-947b-b8cda44ef853",
        content: "Form Openned : FrmViewQRImage",
        numberOfRepetitions: "98",
        countByBusiness: "26",
        COUNTBYBUSINESS: 26,
        NUMBEROFREPETITIONS: 98,
    },
    {
        id: "ffd2f327-7786-4198-835e-d540ea4c29b2",
        content: "Form Openned : FrmBalanceCheck",
        numberOfRepetitions: "69",
        countByBusiness: "24",
        COUNTBYBUSINESS: 24,
        NUMBEROFREPETITIONS: 69,
    },
    {
        id: "9867b877-8040-4ef9-843f-df9037709f80",
        content: "Form Openned : FrmSecondaryDisplay",
        numberOfRepetitions: "7,038",
        countByBusiness: "23",
        COUNTBYBUSINESS: 23,
        NUMBEROFREPETITIONS: 7038,
    },
    {
        id: "8f7957d1-dfc0-4098-8d7d-cf34b8ae1804",
        content: "Form Closed : FrmSecondaryDisplay",
        numberOfRepetitions: "6,063",
        countByBusiness: "23",
        COUNTBYBUSINESS: 23,
        NUMBEROFREPETITIONS: 6063,
    },
    {
        id: "a8e252e5-baf8-4428-bf92-442548b43a9a",
        content: "Form Openned : FrmExch2cAccountState",
        numberOfRepetitions: "4,493",
        countByBusiness: "23",
        COUNTBYBUSINESS: 23,
        NUMBEROFREPETITIONS: 4493,
    },
    {
        id: "a9c08947-6189-44b2-826b-5c0a7af317d9",
        content: "Form Closed : FrmExch2cAccountState",
        numberOfRepetitions: "3,436",
        countByBusiness: "23",
        COUNTBYBUSINESS: 23,
        NUMBEROFREPETITIONS: 3436,
    },
    {
        id: "f4f64b7a-d450-426e-8746-22b25b035820",
        content: "Form Openned : FrmLogFile",
        numberOfRepetitions: "408",
        countByBusiness: "23",
        COUNTBYBUSINESS: 23,
        NUMBEROFREPETITIONS: 408,
    },
    {
        id: "ae7b02e7-1570-4d12-bb2d-b326e11cbd75",
        content: "Form Closed : FrmBalanceCheck",
        numberOfRepetitions: "45",
        countByBusiness: "23",
        COUNTBYBUSINESS: 23,
        NUMBEROFREPETITIONS: 45,
    },
    {
        id: "1bdb21d5-5cdc-4ed1-b879-a79934e2c758",
        content: "Form Closed : FrmLogFile",
        numberOfRepetitions: "386",
        countByBusiness: "22",
        COUNTBYBUSINESS: 22,
        NUMBEROFREPETITIONS: 386,
    },
    {
        id: "44eae959-f924-44b7-814c-d82a738f3eaa",
        content: "Form Openned : FrmTransferDelivery",
        numberOfRepetitions: "3,699",
        countByBusiness: "21",
        COUNTBYBUSINESS: 21,
        NUMBEROFREPETITIONS: 3699,
    },
    {
        id: "231be3d2-a532-40d9-8e92-2a29a930da98",
        content: "Form Closed : FrmTransferDelivery",
        numberOfRepetitions: "3,208",
        countByBusiness: "21",
        COUNTBYBUSINESS: 21,
        NUMBEROFREPETITIONS: 3208,
    },
    {
        id: "4e990698-7cca-444b-a67e-a201733783ac",
        content: "Form Openned : frmItemAggregateForSalse",
        numberOfRepetitions: "1,395",
        countByBusiness: "21",
        COUNTBYBUSINESS: 21,
        NUMBEROFREPETITIONS: 1395,
    },
    {
        id: "007282b7-41f9-4465-9fc4-1b1704ffdcbf",
        content: "Form Closed : frmItemAggregateForSalse",
        numberOfRepetitions: "1,393",
        countByBusiness: "21",
        COUNTBYBUSINESS: 21,
        NUMBEROFREPETITIONS: 1393,
    },
    {
        id: "d599d5f3-3050-4de9-8372-803315c812b6",
        content: "Form Openned : FrmUpdatePremuimData",
        numberOfRepetitions: "144",
        countByBusiness: "21",
        COUNTBYBUSINESS: 21,
        NUMBEROFREPETITIONS: 144,
    },
    {
        id: "1fe24325-328d-470d-b6f7-f010889b7f20",
        content: "Form Closed : FrmUpdatePremuimData",
        numberOfRepetitions: "142",
        countByBusiness: "21",
        COUNTBYBUSINESS: 21,
        NUMBEROFREPETITIONS: 142,
    },
    {
        id: "d45d2505-be07-4328-960f-3e4940321d87",
        content: "Form Openned : FrmInventoryReport",
        numberOfRepetitions: "78",
        countByBusiness: "21",
        COUNTBYBUSINESS: 21,
        NUMBEROFREPETITIONS: 78,
    },
    {
        id: "d8d3c448-3198-418e-84f0-d48669947795",
        content: "Form Openned : FrmDeliveryCompany",
        numberOfRepetitions: "5,644",
        countByBusiness: "20",
        COUNTBYBUSINESS: 20,
        NUMBEROFREPETITIONS: 5644,
    },
    {
        id: "ececcd4f-a7ef-4ebd-a527-f54553c725f9",
        content: "Form Closed : FrmDeliveryCompany",
        numberOfRepetitions: "5,643",
        countByBusiness: "20",
        COUNTBYBUSINESS: 20,
        NUMBEROFREPETITIONS: 5643,
    },
    {
        id: "40ecf563-13f8-44c1-a776-2a42b3b52ad3",
        content: "Form Closed : FrmInventoryReport",
        numberOfRepetitions: "37",
        countByBusiness: "20",
        COUNTBYBUSINESS: 20,
        NUMBEROFREPETITIONS: 37,
    },
    {
        id: "f12998aa-02f0-4e3c-987b-f8c413c6c1fd",
        content: "Form Openned : FrmBoxesBalancesHistory",
        numberOfRepetitions: "2,467",
        countByBusiness: "19",
        COUNTBYBUSINESS: 19,
        NUMBEROFREPETITIONS: 2467,
    },
    {
        id: "b5811abc-868d-4d16-bcdc-e2d233480076",
        content: "Form Openned : FrmTransferMovments",
        numberOfRepetitions: "497",
        countByBusiness: "19",
        COUNTBYBUSINESS: 19,
        NUMBEROFREPETITIONS: 497,
    },
    {
        id: "df15259c-9f7e-448c-98c5-6b097c686210",
        content: "Form Openned : FrmSellAndBuyCurrency",
        numberOfRepetitions: "496",
        countByBusiness: "19",
        COUNTBYBUSINESS: 19,
        NUMBEROFREPETITIONS: 496,
    },
    {
        id: "96a95926-183d-4b11-86bb-27e8ee7afeea",
        content: "Form Closed : FrmSellAndBuyCurrency",
        numberOfRepetitions: "484",
        countByBusiness: "19",
        COUNTBYBUSINESS: 19,
        NUMBEROFREPETITIONS: 484,
    },
    {
        id: "7158c4cb-0e14-4ba6-8e91-7a21760b58c4",
        content: "Form Openned : frmItemAggregate",
        numberOfRepetitions: "233",
        countByBusiness: "19",
        COUNTBYBUSINESS: 19,
        NUMBEROFREPETITIONS: 233,
    },
    {
        id: "285bc9c9-ac8d-45af-b284-1869f520d0f0",
        content: "Form Closed : frmItemAggregate",
        numberOfRepetitions: "233",
        countByBusiness: "19",
        COUNTBYBUSINESS: 19,
        NUMBEROFREPETITIONS: 233,
    },
    {
        id: "39d1629d-b36b-45f4-90d3-463faf6f53fc",
        content: "Form Closed : FrmBoxesBalancesHistory",
        numberOfRepetitions: "2,359",
        countByBusiness: "18",
        COUNTBYBUSINESS: 18,
        NUMBEROFREPETITIONS: 2359,
    },
    {
        id: "a1af8acf-458f-4cd4-94e4-077ff85db0ef",
        content: "Form Closed : FrmTransferMovments",
        numberOfRepetitions: "447",
        countByBusiness: "17",
        COUNTBYBUSINESS: 17,
        NUMBEROFREPETITIONS: 447,
    },
    {
        id: "8d36d645-dde4-4f02-bbbf-a237777ecb26",
        content: "Form Openned : FrmSuggestItems",
        numberOfRepetitions: "406",
        countByBusiness: "17",
        COUNTBYBUSINESS: 17,
        NUMBEROFREPETITIONS: 406,
    },
    {
        id: "bba819c6-cc5d-4c06-888d-300073249d76",
        content: "Form Closed : FrmSuggestItems",
        numberOfRepetitions: "406",
        countByBusiness: "17",
        COUNTBYBUSINESS: 17,
        NUMBEROFREPETITIONS: 406,
    },
    {
        id: "8c644aab-c058-4a40-b053-080ba44922f5",
        content: "Form Openned : FrmFastSellAndBuyCurrencies",
        numberOfRepetitions: "457",
        countByBusiness: "16",
        COUNTBYBUSINESS: 16,
        NUMBEROFREPETITIONS: 457,
    },
    {
        id: "90b1c795-d449-4ad8-8afd-3d451709b2c6",
        content: "Form Closed : FrmFastSellAndBuyCurrencies",
        numberOfRepetitions: "421",
        countByBusiness: "16",
        COUNTBYBUSINESS: 16,
        NUMBEROFREPETITIONS: 421,
    },
    {
        id: "0b604793-97d7-45fd-9414-a1294d98ce09",
        content: "Form Openned : FrmExplainBuyPrice",
        numberOfRepetitions: "129",
        countByBusiness: "16",
        COUNTBYBUSINESS: 16,
        NUMBEROFREPETITIONS: 129,
    },
    {
        id: "0c2c5871-53ac-4aa4-8b03-b3cfd67a7bb0",
        content: "Form Closed : FrmExplainBuyPrice",
        numberOfRepetitions: "129",
        countByBusiness: "16",
        COUNTBYBUSINESS: 16,
        NUMBEROFREPETITIONS: 129,
    },
    {
        id: "f219a292-fd34-40bd-bccc-a3b89a62f334",
        content: "Form Openned : FrmCheckerSupplierEditView",
        numberOfRepetitions: "119",
        countByBusiness: "16",
        COUNTBYBUSINESS: 16,
        NUMBEROFREPETITIONS: 119,
    },
    {
        id: "8984775c-e2c2-41c3-89d3-50ef986b6376",
        content: "Form Closed : FrmCheckerSupplierEditView",
        numberOfRepetitions: "117",
        countByBusiness: "16",
        COUNTBYBUSINESS: 16,
        NUMBEROFREPETITIONS: 117,
    },
    {
        id: "a02cde2c-527b-4f5e-8900-14c09067e593",
        content: "Form Openned : FrmEmployee",
        numberOfRepetitions: "105",
        countByBusiness: "16",
        COUNTBYBUSINESS: 16,
        NUMBEROFREPETITIONS: 105,
    },
    {
        id: "ee98d31e-efce-4614-bb1b-b4169619c67e",
        content: "Form Closed : FrmEmployee",
        numberOfRepetitions: "104",
        countByBusiness: "16",
        COUNTBYBUSINESS: 16,
        NUMBEROFREPETITIONS: 104,
    },
    {
        id: "175d5af1-f674-4734-a20d-e3710f4da9b3",
        content: "Form Openned : FrmViewText",
        numberOfRepetitions: "55",
        countByBusiness: "15",
        COUNTBYBUSINESS: 15,
        NUMBEROFREPETITIONS: 55,
    },
    {
        id: "034ac80b-7025-41fc-a733-b9adb1126407",
        content: "Form Closed : FrmViewText",
        numberOfRepetitions: "55",
        countByBusiness: "15",
        COUNTBYBUSINESS: 15,
        NUMBEROFREPETITIONS: 55,
    },
    {
        id: "656ca692-3ee1-4ecb-8ec9-9e32988e6aa7",
        content: "Form Openned : FrmExchAccountSummary",
        numberOfRepetitions: "689",
        countByBusiness: "13",
        COUNTBYBUSINESS: 13,
        NUMBEROFREPETITIONS: 689,
    },
    {
        id: "d6e428ca-a4de-4fe7-bd6a-ab3d3183d629",
        content: "Form Openned : FrmTransferReporting",
        numberOfRepetitions: "367",
        countByBusiness: "13",
        COUNTBYBUSINESS: 13,
        NUMBEROFREPETITIONS: 367,
    },
    {
        id: "03738b71-00ee-46c0-bbb8-766fbb25e147",
        content: "Form Closed : FrmTransferReporting",
        numberOfRepetitions: "346",
        countByBusiness: "13",
        COUNTBYBUSINESS: 13,
        NUMBEROFREPETITIONS: 346,
    },
    {
        id: "be83a791-8770-4d00-8a62-3da9a65eb8f8",
        content: "Form Closed : FrmExchAccountSummary",
        numberOfRepetitions: "680",
        countByBusiness: "12",
        COUNTBYBUSINESS: 12,
        NUMBEROFREPETITIONS: 680,
    },
    {
        id: "2e2b778a-401b-4664-b4b3-6ae88ebac842",
        content: "Form Openned : FrmDeliveryCostInfo",
        numberOfRepetitions: "380",
        countByBusiness: "12",
        COUNTBYBUSINESS: 12,
        NUMBEROFREPETITIONS: 380,
    },
    {
        id: "e543c525-9f8a-4c55-9683-24e11d8e1a64",
        content: "Form Closed : FrmDeliveryCostInfo",
        numberOfRepetitions: "380",
        countByBusiness: "12",
        COUNTBYBUSINESS: 12,
        NUMBEROFREPETITIONS: 380,
    },
    {
        id: "d0e4bede-dd18-44e7-a0a5-e6a0ef73faa2",
        content: "Form Openned : FrmDepartment",
        numberOfRepetitions: "34",
        countByBusiness: "12",
        COUNTBYBUSINESS: 12,
        NUMBEROFREPETITIONS: 34,
    },
    {
        id: "67a216eb-b4e3-46a8-96f3-bd9047ad80fc",
        content: "Form Openned : FrmActualValueLogView",
        numberOfRepetitions: "31",
        countByBusiness: "11",
        COUNTBYBUSINESS: 11,
        NUMBEROFREPETITIONS: 31,
    },
    {
        id: "b0b2b066-1f29-40fe-8963-0c2429ef442a",
        content: "Form Closed : FrmActualValueLogView",
        numberOfRepetitions: "31",
        countByBusiness: "11",
        COUNTBYBUSINESS: 11,
        NUMBEROFREPETITIONS: 31,
    },
    {
        id: "0dfdd8fb-0b7a-4b80-845f-923a47324cac",
        content: "Form Closed : FrmDepartment",
        numberOfRepetitions: "29",
        countByBusiness: "10",
        COUNTBYBUSINESS: 10,
        NUMBEROFREPETITIONS: 29,
    },
    {
        id: "30bc36dd-1122-4960-8309-8c193cb24dff",
        content: "Form Openned : FrmBoxesBalance",
        numberOfRepetitions: "117",
        countByBusiness: "9",
        COUNTBYBUSINESS: 9,
        NUMBEROFREPETITIONS: 117,
    },
    {
        id: "672a2f31-63d7-49bb-8ab7-b153c3da5d7e",
        content: "Form Openned : FrmItemRequest",
        numberOfRepetitions: "40",
        countByBusiness: "9",
        COUNTBYBUSINESS: 9,
        NUMBEROFREPETITIONS: 40,
    },
    {
        id: "66b86b7f-6bce-4544-a97b-047a1a8ae45d",
        content: "Form Closed : FrmItemRequest",
        numberOfRepetitions: "40",
        countByBusiness: "9",
        COUNTBYBUSINESS: 9,
        NUMBEROFREPETITIONS: 40,
    },
    {
        id: "9ba3a857-4acc-4507-8b28-7086df851a4d",
        content: "Form Openned : FrmEditItemPrices",
        numberOfRepetitions: "38",
        countByBusiness: "9",
        COUNTBYBUSINESS: 9,
        NUMBEROFREPETITIONS: 38,
    },
    {
        id: "2d1d1197-a30b-4423-bb8a-c3e078af5120",
        content: "Form Closed : FrmEditItemPrices",
        numberOfRepetitions: "35",
        countByBusiness: "9",
        COUNTBYBUSINESS: 9,
        NUMBEROFREPETITIONS: 35,
    },
    {
        id: "d32c1895-f4e1-42db-8ced-6948f9d0a839",
        content: "Form Openned : FrmAddCurrecy",
        numberOfRepetitions: "14",
        countByBusiness: "9",
        COUNTBYBUSINESS: 9,
        NUMBEROFREPETITIONS: 14,
    },
    {
        id: "0857f0d7-b4ab-4a34-8c40-8775d5f2abe9",
        content: "Form Closed : FrmAddCurrecy",
        numberOfRepetitions: "14",
        countByBusiness: "9",
        COUNTBYBUSINESS: 9,
        NUMBEROFREPETITIONS: 14,
    },
    {
        id: "e90ee7df-b123-41b3-9d47-7ecd7813a0d0",
        content: "Form Closed : FrmBoxesBalance",
        numberOfRepetitions: "110",
        countByBusiness: "8",
        COUNTBYBUSINESS: 8,
        NUMBEROFREPETITIONS: 110,
    },
    {
        id: "0418e26a-e992-44ac-810e-e7655e2cd58b",
        content: "Form Openned : FrmExchCurrencies",
        numberOfRepetitions: "107",
        countByBusiness: "8",
        COUNTBYBUSINESS: 8,
        NUMBEROFREPETITIONS: 107,
    },
    {
        id: "9aa62361-17d2-4f2b-8aa8-e14a55e808b7",
        content: "Form Closed : FrmExchCurrencies",
        numberOfRepetitions: "98",
        countByBusiness: "8",
        COUNTBYBUSINESS: 8,
        NUMBEROFREPETITIONS: 98,
    },
    {
        id: "4d6a9a92-d953-4b0c-95d6-9477bf6e6f6d",
        content: "Form Openned : FrmEmployeeSchedule",
        numberOfRepetitions: "27",
        countByBusiness: "8",
        COUNTBYBUSINESS: 8,
        NUMBEROFREPETITIONS: 27,
    },
    {
        id: "2d10d07b-0790-40c3-a294-a9b258ac2985",
        content: "Form Closed : FrmEmployeeSchedule",
        numberOfRepetitions: "27",
        countByBusiness: "8",
        COUNTBYBUSINESS: 8,
        NUMBEROFREPETITIONS: 27,
    },
    {
        id: "5c87e0cb-a0b9-424e-b24a-825f3cf0db98",
        content: "Form Closed : FrmCurrencies",
        numberOfRepetitions: "21",
        countByBusiness: "8",
        COUNTBYBUSINESS: 8,
        NUMBEROFREPETITIONS: 21,
    },
    {
        id: "00ddf7d0-0678-4496-922b-72665662455d",
        content: "Form Openned : FrmAddStudent",
        numberOfRepetitions: "929",
        countByBusiness: "7",
        COUNTBYBUSINESS: 7,
        NUMBEROFREPETITIONS: 929,
    },
    {
        id: "7e8b098f-304b-494b-a45f-e8c96e6c0681",
        content: "Form Openned : FrmSchoolPremuimState",
        numberOfRepetitions: "125",
        countByBusiness: "7",
        COUNTBYBUSINESS: 7,
        NUMBEROFREPETITIONS: 125,
    },
    {
        id: "55d453f8-9b49-4181-8fb5-5e750c9c70ce",
        content: "Form Closed : FrmExchTransferMulti",
        numberOfRepetitions: "65",
        countByBusiness: "7",
        COUNTBYBUSINESS: 7,
        NUMBEROFREPETITIONS: 65,
    },
    {
        id: "6e93462b-32eb-45aa-bef4-07878eace124",
        content: "Form Openned : FrmExchTransferMulti",
        numberOfRepetitions: "64",
        countByBusiness: "7",
        COUNTBYBUSINESS: 7,
        NUMBEROFREPETITIONS: 64,
    },
    {
        id: "28c922df-9af4-4685-9dd4-661289b7d873",
        content: "Form Openned : FrmShifts",
        numberOfRepetitions: "25",
        countByBusiness: "7",
        COUNTBYBUSINESS: 7,
        NUMBEROFREPETITIONS: 25,
    },
    {
        id: "0929796f-0a57-447c-8105-0e0dc38d2a58",
        content: "Form Closed : FrmShifts",
        numberOfRepetitions: "24",
        countByBusiness: "7",
        COUNTBYBUSINESS: 7,
        NUMBEROFREPETITIONS: 24,
    },
    {
        id: "f004efe0-ce7b-4416-867f-63279f248299",
        content: "Form Openned : FrmDeliveryCost",
        numberOfRepetitions: "16",
        countByBusiness: "7",
        COUNTBYBUSINESS: 7,
        NUMBEROFREPETITIONS: 16,
    },
    {
        id: "4d1fd61a-49c2-4db2-9b61-1babc2e48e09",
        content: "Form Closed : FrmDeliveryCost",
        numberOfRepetitions: "16",
        countByBusiness: "7",
        COUNTBYBUSINESS: 7,
        NUMBEROFREPETITIONS: 16,
    },
    {
        id: "020a14c1-c3ee-470b-ab03-00905c9cb75d",
        content: "Form Openned : FrmStudentAddPrem",
        numberOfRepetitions: "2,286",
        countByBusiness: "6",
        COUNTBYBUSINESS: 6,
        NUMBEROFREPETITIONS: 2286,
    },
    {
        id: "304c1fb4-8da3-460b-bf77-102742bb5e69",
        content: "Form Closed : FrmAddStudent",
        numberOfRepetitions: "909",
        countByBusiness: "6",
        COUNTBYBUSINESS: 6,
        NUMBEROFREPETITIONS: 909,
    },
    {
        id: "7638e428-3eb4-46e4-abc5-e1dbc199940a",
        content: "Form Closed : FrmSchoolPremuimState",
        numberOfRepetitions: "105",
        countByBusiness: "6",
        COUNTBYBUSINESS: 6,
        NUMBEROFREPETITIONS: 105,
    },
    {
        id: "34f6bb89-0eb2-4e05-a9bd-ee14ba6e0638",
        content: "Form Closed : FrmAddDestination",
        numberOfRepetitions: "48",
        countByBusiness: "6",
        COUNTBYBUSINESS: 6,
        NUMBEROFREPETITIONS: 48,
    },
    {
        id: "88da4343-88f9-45b5-a77d-cbe25f0aae3a",
        content: "Form Openned : FrmAddDestination",
        numberOfRepetitions: "47",
        countByBusiness: "6",
        COUNTBYBUSINESS: 6,
        NUMBEROFREPETITIONS: 47,
    },
    {
        id: "14666b97-a5d8-47c6-a57f-171ef4291066",
        content: "Form Openned : FrmLinkEmployees",
        numberOfRepetitions: "46",
        countByBusiness: "6",
        COUNTBYBUSINESS: 6,
        NUMBEROFREPETITIONS: 46,
    },
    {
        id: "bc079347-b461-4d9a-a6d3-5be2a8ce1911",
        content: "Form Openned : FrmShiftSchedule",
        numberOfRepetitions: "25",
        countByBusiness: "6",
        COUNTBYBUSINESS: 6,
        NUMBEROFREPETITIONS: 25,
    },
    {
        id: "b7d4edc4-f529-4fe9-89b9-0dab1ef07e50",
        content: "Form Closed : FrmShiftSchedule",
        numberOfRepetitions: "25",
        countByBusiness: "6",
        COUNTBYBUSINESS: 6,
        NUMBEROFREPETITIONS: 25,
    },
    {
        id: "d5607159-e3c5-4356-82fd-a882ae2a9ba3",
        content: "Form Openned : FrmEployeesReport",
        numberOfRepetitions: "21",
        countByBusiness: "6",
        COUNTBYBUSINESS: 6,
        NUMBEROFREPETITIONS: 21,
    },
    {
        id: "79acdef8-c781-4e21-9c22-5981601c0a81",
        content: "Form Closed : FrmEployeesReport",
        numberOfRepetitions: "21",
        countByBusiness: "6",
        COUNTBYBUSINESS: 6,
        NUMBEROFREPETITIONS: 21,
    },
    {
        id: "9f3ac0e7-88cf-44ab-950f-bc18e5e427ed",
        content: "Form Openned : FrmRating",
        numberOfRepetitions: "13",
        countByBusiness: "6",
        COUNTBYBUSINESS: 6,
        NUMBEROFREPETITIONS: 13,
    },
    {
        id: "349d2bd6-135a-4012-a701-c2dcfee829b0",
        content: "Form Closed : FrmRating",
        numberOfRepetitions: "13",
        countByBusiness: "6",
        COUNTBYBUSINESS: 6,
        NUMBEROFREPETITIONS: 13,
    },
    {
        id: "f183f1d6-345f-4de6-815f-9036778ad348",
        content: "Form Closed : FrmStudentAddPrem",
        numberOfRepetitions: "2,267",
        countByBusiness: "5",
        COUNTBYBUSINESS: 5,
        NUMBEROFREPETITIONS: 2267,
    },
    {
        id: "607a29d9-3732-4dfb-a007-1ddf3121667c",
        content: "Form Closed : FrmSelectChecker",
        numberOfRepetitions: "437",
        countByBusiness: "5",
        COUNTBYBUSINESS: 5,
        NUMBEROFREPETITIONS: 437,
    },
    {
        id: "acd84e20-462e-4e83-a10c-72edb0dcb042",
        content: "Form Openned : FrmSelectChecker",
        numberOfRepetitions: "436",
        countByBusiness: "5",
        COUNTBYBUSINESS: 5,
        NUMBEROFREPETITIONS: 436,
    },
    {
        id: "9e369d7f-a537-4dd6-9f16-689a39a0d1b7",
        content: "Form Openned : FrmAttendenceLogs",
        numberOfRepetitions: "159",
        countByBusiness: "5",
        COUNTBYBUSINESS: 5,
        NUMBEROFREPETITIONS: 159,
    },
    {
        id: "c8561067-c2f5-4b7e-a048-3ffb629d200a",
        content: "Form Closed : FrmAttendenceLogs",
        numberOfRepetitions: "141",
        countByBusiness: "5",
        COUNTBYBUSINESS: 5,
        NUMBEROFREPETITIONS: 141,
    },
    {
        id: "59a04f43-192f-4dbc-a38c-9455000c3757",
        content: "Form Openned : FrmAddAttendanceLog",
        numberOfRepetitions: "38",
        countByBusiness: "5",
        COUNTBYBUSINESS: 5,
        NUMBEROFREPETITIONS: 38,
    },
    {
        id: "025c805d-1f8b-4db6-b46c-717ad3d6f154",
        content: "Form Closed : FrmLinkEmployees",
        numberOfRepetitions: "38",
        countByBusiness: "5",
        COUNTBYBUSINESS: 5,
        NUMBEROFREPETITIONS: 38,
    },
    {
        id: "471e1be6-c7f3-4e44-a6b0-fe0435177e7c",
        content: "Form Closed : FrmAddAttendanceLog",
        numberOfRepetitions: "34",
        countByBusiness: "5",
        COUNTBYBUSINESS: 5,
        NUMBEROFREPETITIONS: 34,
    },
    {
        id: "c358f5c3-c752-406a-8ed9-89faf34a1490",
        content: "Form Openned : FrmAttendanceSummary",
        numberOfRepetitions: "23",
        countByBusiness: "5",
        COUNTBYBUSINESS: 5,
        NUMBEROFREPETITIONS: 23,
    },
    {
        id: "e6e68060-92e1-4d5c-b0c0-0e75b2319df5",
        content: "Form Closed : FrmAttendanceSummary",
        numberOfRepetitions: "21",
        countByBusiness: "5",
        COUNTBYBUSINESS: 5,
        NUMBEROFREPETITIONS: 21,
    },
    {
        id: "2f012a8e-48a1-4b7b-a870-3da0d22ec46d",
        content: "Form Openned : FrmVacations",
        numberOfRepetitions: "12",
        countByBusiness: "5",
        COUNTBYBUSINESS: 5,
        NUMBEROFREPETITIONS: 12,
    },
    {
        id: "d0ac098a-2905-48f5-b83a-b4484246ee0f",
        content: "Form Closed : FrmVacations",
        numberOfRepetitions: "12",
        countByBusiness: "5",
        COUNTBYBUSINESS: 5,
        NUMBEROFREPETITIONS: 12,
    },
    {
        id: "2396ab79-f9ed-4915-bbe4-ea67b9460718",
        content: "Form Openned : FrmSelectDays",
        numberOfRepetitions: "18",
        countByBusiness: "4",
        COUNTBYBUSINESS: 4,
        NUMBEROFREPETITIONS: 18,
    },
    {
        id: "a8156c76-d92a-43b7-8684-5a8500e36133",
        content: "Form Closed : FrmSelectDays",
        numberOfRepetitions: "18",
        countByBusiness: "4",
        COUNTBYBUSINESS: 4,
        NUMBEROFREPETITIONS: 18,
    },
    {
        id: "8973e121-7a6a-40bc-8c91-9dcbfeb84e87",
        content: "Form Openned : FrmFaxReport",
        numberOfRepetitions: "14",
        countByBusiness: "4",
        COUNTBYBUSINESS: 4,
        NUMBEROFREPETITIONS: 14,
    },
    {
        id: "2a2f90fe-a8bd-4a87-ac2f-4d18a12ec4cd",
        content: "Form Closed : FrmFaxReport",
        numberOfRepetitions: "14",
        countByBusiness: "4",
        COUNTBYBUSINESS: 4,
        NUMBEROFREPETITIONS: 14,
    },
    {
        id: "918766eb-c243-414a-9812-5928a1223941",
        content: "Form Openned : FrmSchoolPremiumsDue",
        numberOfRepetitions: "8",
        countByBusiness: "4",
        COUNTBYBUSINESS: 4,
        NUMBEROFREPETITIONS: 8,
    },
    {
        id: "272c0fde-51cf-4bee-99db-6bd29c76eddd",
        content: "Form Closed : FrmSchoolPremiumsDue",
        numberOfRepetitions: "8",
        countByBusiness: "4",
        COUNTBYBUSINESS: 4,
        NUMBEROFREPETITIONS: 8,
    },
    {
        id: "e253bb96-31e0-4389-a383-5a89e86b5c6e",
        content: "Form Openned : FrmExchangeTickitsReport",
        numberOfRepetitions: "8",
        countByBusiness: "4",
        COUNTBYBUSINESS: 4,
        NUMBEROFREPETITIONS: 8,
    },
    {
        id: "9e444889-ba54-4d7f-b774-e9323b65b469",
        content: "Form Openned : FrmLinkBillWithDeliveryCompany",
        numberOfRepetitions: "8",
        countByBusiness: "4",
        COUNTBYBUSINESS: 4,
        NUMBEROFREPETITIONS: 8,
    },
    {
        id: "e703d245-56af-4e66-94bf-704ee1620f3f",
        content: "Form Closed : FrmLinkBillWithDeliveryCompany",
        numberOfRepetitions: "8",
        countByBusiness: "4",
        COUNTBYBUSINESS: 4,
        NUMBEROFREPETITIONS: 8,
    },
    {
        id: "6fa2713e-f7eb-443e-819e-1b774f553bce",
        content: "Form Openned : FrmHotelReservationsDialog",
        numberOfRepetitions: "6,482",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 6482,
    },
    {
        id: "75c78b44-9e00-4e77-9fdc-80f6161a52e5",
        content: "Form Closed : FrmHotelReservationsDialog",
        numberOfRepetitions: "6,473",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 6473,
    },
    {
        id: "a5e8eca1-a0da-4674-ab51-9f296394d360",
        content: "Form Openned : frmTransferFastCopy",
        numberOfRepetitions: "299",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 299,
    },
    {
        id: "7663e2ae-5147-4212-b82e-39e063692ffd",
        content: "Form Closed : frmTransferFastCopy",
        numberOfRepetitions: "298",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 298,
    },
    {
        id: "dbea5381-c42a-48c4-8eb8-fb39349247ab",
        content: "Form Openned : FrmHotelReservations",
        numberOfRepetitions: "248",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 248,
    },
    {
        id: "358ee101-e57d-4d82-9e1f-6fe30aa47307",
        content: "Form Openned : FrmAddClass",
        numberOfRepetitions: "72",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 72,
    },
    {
        id: "5d86f482-2b06-4df9-9681-bb4a99382611",
        content: "Form Closed : FrmAddClass",
        numberOfRepetitions: "72",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 72,
    },
    {
        id: "fbb1f684-c0d0-42bb-9bac-19e09f7a79b7",
        content: "Form Openned : ViewWaitingBonds",
        numberOfRepetitions: "69",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 69,
    },
    {
        id: "89efdd1b-891e-4271-a163-2291b68bf3a3",
        content: "Form Closed : ViewWaitingBonds",
        numberOfRepetitions: "69",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 69,
    },
    {
        id: "c80eb9b7-c351-46f6-93be-9341830d572e",
        content: "Form Openned : FrmItemsMatching",
        numberOfRepetitions: "47",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 47,
    },
    {
        id: "2bc2ab30-d282-4d5c-9d95-0ec93db03a0f",
        content: "Form Closed : FrmItemsMatching",
        numberOfRepetitions: "47",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 47,
    },
    {
        id: "08e44c9f-adbf-404d-a64d-000ecbb50fb7",
        content: "Form Openned : FrmStages",
        numberOfRepetitions: "40",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 40,
    },
    {
        id: "8aa2826d-e68e-4b17-8da4-5984fb9479f4",
        content: "Form Closed : FrmStages",
        numberOfRepetitions: "38",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 38,
    },
    {
        id: "17420e18-4bc9-4e5b-aa6b-294345b6f35a",
        content: "Form Openned : FrmFormulaOptions",
        numberOfRepetitions: "34",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 34,
    },
    {
        id: "7f98350f-db92-4c9c-9b77-36fa49a069a9",
        content: "Form Closed : FrmFormulaOptions",
        numberOfRepetitions: "34",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 34,
    },
    {
        id: "f0476f99-5912-4a73-9df7-38921e04080b",
        content: "Form Openned : FrmSchoolPremiumPaystate",
        numberOfRepetitions: "28",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 28,
    },
    {
        id: "3a4fc10c-6039-40ae-955e-c153672f184a",
        content: "Form Closed : FrmSchoolPremiumPaystate",
        numberOfRepetitions: "26",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 26,
    },
    {
        id: "490821bc-2702-4f3f-8c4b-5c8f4ba5caee",
        content: "Form Openned : frmSolveItemBuyPrice",
        numberOfRepetitions: "25",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 25,
    },
    {
        id: "11927828-95ca-48a0-9da9-c05bca26dba2",
        content: "Form Openned : FrmSelectedEmployees",
        numberOfRepetitions: "21",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 21,
    },
    {
        id: "a16260a8-b9a8-4bf1-b89c-a062daa5d907",
        content: "Form Closed : FrmSelectedEmployees",
        numberOfRepetitions: "21",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 21,
    },
    {
        id: "7497601a-8ec6-4ade-a207-57b5006d5a20",
        content: "Form Openned : FrmGuestDetailsReport",
        numberOfRepetitions: "21",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 21,
    },
    {
        id: "936204f5-3e74-46c4-b5a9-df75eb995fd3",
        content: "Form Closed : frmSolveItemBuyPrice",
        numberOfRepetitions: "17",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 17,
    },
    {
        id: "12718cfc-8293-49de-9aea-3478e6cb20f0",
        content: "Form Openned : FrmBlackList",
        numberOfRepetitions: "15",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 15,
    },
    {
        id: "40c1d196-51e0-4952-ad89-65d25ab5255c",
        content: "Form Openned : FrmAdministrativeOrdersView",
        numberOfRepetitions: "13",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 13,
    },
    {
        id: "b42787f3-6875-4945-a2cf-6319fd825666",
        content: "Form Closed : FrmAdministrativeOrdersView",
        numberOfRepetitions: "13",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 13,
    },
    {
        id: "266aa9be-d859-4163-8f4f-d86c0e939e7b",
        content: "Form Openned : FrmAdministrativeOrders",
        numberOfRepetitions: "13",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 13,
    },
    {
        id: "8461d9d0-85b5-4082-befa-6f8fcf9f5c4d",
        content: "Form Closed : FrmAdministrativeOrders",
        numberOfRepetitions: "13",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 13,
    },
    {
        id: "456e580d-3f39-4ce2-b741-69c84564bf7d",
        content: "Form Openned : FrmMatching",
        numberOfRepetitions: "12",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 12,
    },
    {
        id: "81779774-34a8-4d8b-8dbb-4372f9499715",
        content: "Form Closed : FrmMatching",
        numberOfRepetitions: "12",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 12,
    },
    {
        id: "96c55fa4-e913-440d-ba5c-be20c37ec34f",
        content: "Form Openned : frmAccountSummaryTree",
        numberOfRepetitions: "11",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 11,
    },
    {
        id: "7fb8cf69-7ae6-4b73-9ac7-aac01ced34ca",
        content: "Form Closed : frmAccountSummaryTree",
        numberOfRepetitions: "11",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 11,
    },
    {
        id: "50646927-8d38-4485-b8a5-d652aa06c9e4",
        content: "Form Openned : FrmExchangeAScenario",
        numberOfRepetitions: "10",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 10,
    },
    {
        id: "d4a8dd37-cf76-4412-9655-78b29dd13619",
        content: "Form Openned : FrmAccountingTree",
        numberOfRepetitions: "7",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 7,
    },
    {
        id: "0e8d7407-6538-46ff-891d-d4b3f80bb71b",
        content: "Form Closed : FrmAccountingTree",
        numberOfRepetitions: "7",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 7,
    },
    {
        id: "085f144c-0267-46be-ac12-350a7e30feb0",
        content: "Form Openned : FrmPeriodicInventory",
        numberOfRepetitions: "6",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 6,
    },
    {
        id: "9618525b-3261-4ffa-8755-eb588563d8e4",
        content: "Form Openned : FrmImportExchangeTickits",
        numberOfRepetitions: "6",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 6,
    },
    {
        id: "b0e325ec-3c3b-4d13-a29e-99df4f201ed9",
        content: "Form Openned : FrmMatchingReport",
        numberOfRepetitions: "6",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 6,
    },
    {
        id: "10879a6a-62d3-4446-9728-4a646fe82c83",
        content: "Form Openned : AddBranch",
        numberOfRepetitions: "5",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 5,
    },
    {
        id: "a87264c5-547b-4736-bce3-14cc89c48e64",
        content: "Form Closed : AddBranch",
        numberOfRepetitions: "5",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 5,
    },
    {
        id: "ef760792-0b99-4fc5-af96-bf79aacfd936",
        content: "Form Openned : FrmExchProfitAndLosses",
        numberOfRepetitions: "5",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 5,
    },
    {
        id: "5090f1ed-b2a9-4c3f-9e43-9bf165deb870",
        content: "Form Closed : FrmExchProfitAndLosses",
        numberOfRepetitions: "5",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 5,
    },
    {
        id: "1905c1e3-6327-40b9-8384-025f81cc72bd",
        content: "Form Openned : FrmUserBranch",
        numberOfRepetitions: "3",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 3,
    },
    {
        id: "81e6a06a-cb5f-43a2-919b-3474ed7ef9ba",
        content: "Form Closed : FrmUserBranch",
        numberOfRepetitions: "3",
        countByBusiness: "3",
        COUNTBYBUSINESS: 3,
        NUMBEROFREPETITIONS: 3,
    },
    {
        id: "3ec1bc37-5e76-432d-97ab-1594c85d75cc",
        content: "Form Openned : FrmGuestGeneralReport",
        numberOfRepetitions: "129",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 129,
    },
    {
        id: "141e1ad6-cb65-4a22-9053-fff995a8aa76",
        content: "Form Closed : FrmGuestGeneralReport",
        numberOfRepetitions: "115",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 115,
    },
    {
        id: "b76dd6f3-249e-45e9-b294-a89fcfcb482c",
        content: "Form Closed : FrmAddRoom",
        numberOfRepetitions: "91",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 91,
    },
    {
        id: "b7135dab-ec9d-4412-b2cd-63f5d3ec2e2c",
        content: "Form Openned : FrmAddRoom",
        numberOfRepetitions: "90",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 90,
    },
    {
        id: "edf5ec29-8517-48c1-bb17-9d2e09e6e77c",
        content: "Form Closed : FrmHotelReservations",
        numberOfRepetitions: "53",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 53,
    },
    {
        id: "3f23a89e-b3cf-4bac-b0ba-d54fbd686490",
        content: "Form Openned : FrmSelectedShifts",
        numberOfRepetitions: "53",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 53,
    },
    {
        id: "0cfb5515-829d-4d93-b25a-d0baa7eef72c",
        content: "Form Closed : FrmSelectedShifts",
        numberOfRepetitions: "53",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 53,
    },
    {
        id: "d7100297-8ddd-4c5d-9472-9d9b7d6a345b",
        content: "Form Openned : FrmUnvalidReservations",
        numberOfRepetitions: "35",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 35,
    },
    {
        id: "1943cd72-f233-4789-9e64-2799c28f1910",
        content: "Form Closed : FrmUnvalidReservations",
        numberOfRepetitions: "34",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 34,
    },
    {
        id: "22f1798e-c5a6-46c6-9856-f0fb93758fb5",
        content: "Form Openned : FrmSelectEmployeeSchedules",
        numberOfRepetitions: "32",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 32,
    },
    {
        id: "a01611d9-7739-4bcb-be17-99e5ce7219fe",
        content: "Form Closed : FrmSelectEmployeeSchedules",
        numberOfRepetitions: "32",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 32,
    },
    {
        id: "cd8befda-d53b-48ec-ac46-bdedf5420cd8",
        content: "Form Openned : FrmMultiplebooking",
        numberOfRepetitions: "27",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 27,
    },
    {
        id: "28aee693-3d9d-4929-aed4-2ffd162293f4",
        content: "Form Openned : FrmAddStudyYear",
        numberOfRepetitions: "24",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 24,
    },
    {
        id: "b6326972-da71-4771-a738-6664b12cbdef",
        content: "Form Closed : FrmMultiplebooking",
        numberOfRepetitions: "24",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 24,
    },
    {
        id: "d326c9d5-a36e-4b39-8dfa-01a7bb1be847",
        content: "Form Closed : FrmAddStudyYear",
        numberOfRepetitions: "20",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 20,
    },
    {
        id: "0ee29e10-34a2-48e6-9f3e-d224d33c5239",
        content: "Form Closed : FrmGuestDetailsReport",
        numberOfRepetitions: "18",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 18,
    },
    {
        id: "9e181b14-caac-401a-b686-f344d80fe11a",
        content: "Form Openned : FrmTrialBalance",
        numberOfRepetitions: "10",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 10,
    },
    {
        id: "5f61b869-a2e1-48ab-bf35-3af6e332ee5f",
        content: "Form Closed : FrmTrialBalance",
        numberOfRepetitions: "10",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 10,
    },
    {
        id: "d68eda71-0d71-4159-9782-5ffea0ff0b37",
        content: "Form Openned : FrmStatusChange",
        numberOfRepetitions: "10",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 10,
    },
    {
        id: "f247e7de-0686-4611-b902-07ef6190f3a7",
        content: "Form Closed : FrmStatusChange",
        numberOfRepetitions: "10",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 10,
    },
    {
        id: "6cd48c14-12c4-4e93-96fb-ef7824d961cd",
        content: "Form Closed : FrmExchangeAScenario",
        numberOfRepetitions: "8",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 8,
    },
    {
        id: "70b99c3d-6d65-4a10-ad7e-c43f551151b8",
        content: "Form Closed : FrmBlackList",
        numberOfRepetitions: "8",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 8,
    },
    {
        id: "3945ae8d-71f5-424a-ae5c-3013ea0a117b",
        content: "Form Openned : FrmExchangeTickits",
        numberOfRepetitions: "7",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 7,
    },
    {
        id: "52d284dd-fa3c-46e9-8c8f-65a5b261a5d3",
        content: "Form Closed : FrmExchangeTickits",
        numberOfRepetitions: "7",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 7,
    },
    {
        id: "301849ce-2ead-4b3d-b115-36910885933f",
        content: "Form Openned : FrmOnlineStoreBills",
        numberOfRepetitions: "7",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 7,
    },
    {
        id: "ca193414-a893-4b72-a0a0-4a5ef67ac2fe",
        content: "Form Openned : FrmRestoreHistory",
        numberOfRepetitions: "6",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 6,
    },
    {
        id: "8d37b92e-f9f1-4b2a-b8ac-23cabd4680c4",
        content: "Form Closed : FrmRestoreHistory",
        numberOfRepetitions: "6",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 6,
    },
    {
        id: "0f2f3696-8f0e-4dc7-b771-358087ba374e",
        content: "Form Closed : FrmOnlineStoreBills",
        numberOfRepetitions: "5",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 5,
    },
    {
        id: "1e98bcd1-8df9-4557-b40d-12a2a4ac1da9",
        content: "Form Openned : FrmCampaignsReport",
        numberOfRepetitions: "5",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 5,
    },
    {
        id: "7b22ab6c-8921-475d-bb86-6b0657a1513d",
        content: "Form Closed : FrmCampaignsReport",
        numberOfRepetitions: "5",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 5,
    },
    {
        id: "191c59f7-78b2-487b-b383-c935221806db",
        content: "Form Openned : FrmDepartments",
        numberOfRepetitions: "4",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 4,
    },
    {
        id: "9fd93f42-0171-488e-8530-b3a7827f4e64",
        content: "Form Closed : FrmDepartments",
        numberOfRepetitions: "4",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 4,
    },
    {
        id: "c694a258-e018-4445-b2af-12231e10387e",
        content: "Form Closed : FrmExchangeTickitsReport",
        numberOfRepetitions: "4",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 4,
    },
    {
        id: "14053db7-06ee-456b-aa45-458962d9f916",
        content: "Form Closed : FrmMatchingReport",
        numberOfRepetitions: "4",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 4,
    },
    {
        id: "b04f7809-e6a7-40eb-bf14-74767b1bd514",
        content: "Form Closed : FrmPeriodicInventory",
        numberOfRepetitions: "3",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 3,
    },
    {
        id: "77424625-ad7c-4211-8fde-e62bb8ce746a",
        content: "Form Openned : ClearProfitsExpenses",
        numberOfRepetitions: "3",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 3,
    },
    {
        id: "9611a9be-cdc2-44b7-baba-af6cc2b62c42",
        content: "Form Closed : ClearProfitsExpenses",
        numberOfRepetitions: "3",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 3,
    },
    {
        id: "dd21d8b1-9d62-47bb-a285-51796322441c",
        content: "Form Openned : FrmUploadPCConfigration",
        numberOfRepetitions: "2",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 2,
    },
    {
        id: "1415ac62-53e6-495d-b772-af02d983cc9f",
        content: "Form Closed : FrmUploadPCConfigration",
        numberOfRepetitions: "2",
        countByBusiness: "2",
        COUNTBYBUSINESS: 2,
        NUMBEROFREPETITIONS: 2,
    },
    {
        id: "dca2d895-60a0-4943-9ce6-3c10ab1862a0",
        content: "Form Openned : frmWelcome",
        numberOfRepetitions: "581,832",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 581832,
    },
    {
        id: "b6e20600-9a94-440d-9bb2-300b08669ca1",
        content: "Form Openned : FrmVersionType",
        numberOfRepetitions: "471",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 471,
    },
    {
        id: "f6097bff-268b-4ad9-9091-7ef6c60f03e6",
        content: "Form Closed : FrmVersionType",
        numberOfRepetitions: "418",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 418,
    },
    {
        id: "ae87d968-2091-4458-ae86-657292760adb",
        content: "Form Closed : FrmBoxesOverView",
        numberOfRepetitions: "292",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 292,
    },
    {
        id: "3f6c581f-6f29-4aeb-9737-c6ed62079172",
        content: "Form Openned : FrmBoxesOverView",
        numberOfRepetitions: "289",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 289,
    },
    {
        id: "ef3de88e-eb0f-4b0e-aa87-6999e7f3e1ab",
        content: "Form Openned : frmSeed15Progress",
        numberOfRepetitions: "169",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 169,
    },
    {
        id: "7affa8f2-f96e-4bb0-b34c-b5f66572d8f9",
        content: "Form Closed : frmSeed15Progress",
        numberOfRepetitions: "169",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 169,
    },
    {
        id: "0f9d88f2-6ff6-4b6d-8549-d393c7dfb2da",
        content: "Form Openned : FrmSelectDistributer",
        numberOfRepetitions: "40",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 40,
    },
    {
        id: "28865ad8-b5f7-486c-aa18-fce6e8b08af4",
        content: "Form Closed : FrmSelectDistributer",
        numberOfRepetitions: "40",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 40,
    },
    {
        id: "05dde6dc-07ff-4c79-8907-cccf3d647c07",
        content: "Form Openned : FrmAddSchool",
        numberOfRepetitions: "38",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 38,
    },
    {
        id: "e8893b4f-c518-4bd4-825f-b43d849794ee",
        content: "Form Closed : FrmAddSchool",
        numberOfRepetitions: "36",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 36,
    },
    {
        id: "e7ec5386-194c-4fd2-8f38-f732cc22c288",
        content: "Form Openned : FrmConditions",
        numberOfRepetitions: "4",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 4,
    },
    {
        id: "435157a2-e5ed-4b86-ad1a-f1181e1b97b0",
        content: "Form Closed : FrmConditions",
        numberOfRepetitions: "4",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 4,
    },
    {
        id: "aaa85849-a08a-41e0-a4a4-f16a4d53b703",
        content: "Form Openned : FrmViewSalariesBonds",
        numberOfRepetitions: "4",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 4,
    },
    {
        id: "1fcf0ce7-17d0-47bc-bbd5-5c5a9d1223f8",
        content: "Form Closed : FrmViewSalariesBonds",
        numberOfRepetitions: "4",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 4,
    },
    {
        id: "8f3b3f82-c9a7-424c-8333-5770777ca391",
        content: "Form Openned : FrmOnlineMazkerOffers",
        numberOfRepetitions: "3",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 3,
    },
    {
        id: "56117d76-831e-4339-b2a2-d267aa40f380",
        content: "Form Closed : FrmOnlineMazkerOffers",
        numberOfRepetitions: "3",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 3,
    },
    {
        id: "4cde426d-4cd0-4afd-823b-177ae501c32c",
        content: "Form Openned : FrmHotelDocuments",
        numberOfRepetitions: "3",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 3,
    },
    {
        id: "0f792a3b-0070-4eb1-8b8a-67300e8db479",
        content: "Form Closed : FrmHotelDocuments",
        numberOfRepetitions: "3",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 3,
    },
    {
        id: "55ed4778-1011-46b4-afcd-b27bd07b6063",
        content: "Form Openned : FrmPromotesAndDemotesReport",
        numberOfRepetitions: "3",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 3,
    },
    {
        id: "33c3512b-5f5e-4acf-9cfb-894bf9a249ac",
        content: "Form Closed : FrmPromotesAndDemotesReport",
        numberOfRepetitions: "3",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 3,
    },
    {
        id: "42d85c95-b6b8-461b-8888-77b266cf3913",
        content: "Form Closed : FrmImportExchangeTickits",
        numberOfRepetitions: "2",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 2,
    },
    {
        id: "b9226a17-e9ae-4cb7-bcf4-3b4ac7216fa5",
        content: "Form Openned : frmConversion",
        numberOfRepetitions: "2",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 2,
    },
    {
        id: "342c4e6b-3f70-4b91-8d8d-3cc764487951",
        content: "Form Closed : frmConversion",
        numberOfRepetitions: "2",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 2,
    },
    {
        id: "a9654fd6-13b5-4a10-b6fc-fc5245e78375",
        content: "Form Openned : FrmOnlineMazkerAddOffer",
        numberOfRepetitions: "2",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 2,
    },
    {
        id: "8ac8ce92-d136-4c1b-b801-cba36b1b2280",
        content: "Form Closed : FrmOnlineMazkerAddOffer",
        numberOfRepetitions: "2",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 2,
    },
    {
        id: "95b4bc0a-0bae-4dd3-90de-905686cff8f9",
        content: "Form Openned : FrmAdministrativeOrderDetails",
        numberOfRepetitions: "2",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 2,
    },
    {
        id: "7a4532d1-bb5b-4828-bfa5-200194f77c7a",
        content: "Form Closed : FrmAdministrativeOrderDetails",
        numberOfRepetitions: "2",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 2,
    },
    {
        id: "2458c916-eeda-4676-bc60-7f7f033b1b45",
        content: "Form Closed : FrmChequeReport",
        numberOfRepetitions: "2",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 2,
    },
    {
        id: "e718910d-f351-41c7-8828-592a3a3d5187",
        content: "Form Openned : FrmAttendanceSummaryPrintSettings",
        numberOfRepetitions: "2",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 2,
    },
    {
        id: "ddbd7010-7060-4488-94da-406611a24ef8",
        content: "Form Closed : FrmAttendanceSummaryPrintSettings",
        numberOfRepetitions: "2",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 2,
    },
    {
        id: "8ade66d3-9256-42c2-920b-5ee61fbb45d8",
        content: "Form Openned : FrmSelect",
        numberOfRepetitions: "1",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 1,
    },
    {
        id: "c9cfc331-747a-40e3-86e3-f900a12b5f1e",
        content: "Form Closed : FrmSelect",
        numberOfRepetitions: "1",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 1,
    },
    {
        id: "d2f8440c-2bfd-47ca-9b35-83792d36e9b3",
        content: "Form Openned : FrmAddStorey",
        numberOfRepetitions: "1",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 1,
    },
    {
        id: "95ef461b-3d41-4390-a357-2a070853abac",
        content: "Form Closed : FrmAddStorey",
        numberOfRepetitions: "1",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 1,
    },
    {
        id: "bfead84f-5a19-445b-942d-5919b2fc4399",
        content: "Form Openned : FrmInformedCompany",
        numberOfRepetitions: "1",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 1,
    },
    {
        id: "07d49875-da46-413d-9438-912c4ced8340",
        content: "Form Closed : FrmInformedCompany",
        numberOfRepetitions: "1",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 1,
    },
    {
        id: "74bdffd9-d6bf-4049-9cac-06fc5e04ba63",
        content: "Form Openned : FrmTaxRules",
        numberOfRepetitions: "1",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 1,
    },
    {
        id: "59d3ac61-b69a-4235-8aea-85beab5526ee",
        content: "Form Closed : FrmTaxRules",
        numberOfRepetitions: "1",
        countByBusiness: "1",
        COUNTBYBUSINESS: 1,
        NUMBEROFREPETITIONS: 1,
    },
];
