const test = [
    {
        baseURL: "https://repsapi.morabaaapps.com/api/v1",
        headers: { "App-Package": "com.morabaa.reps", Authorization: localStorage.getItem("token") },
    },
    {
        baseURL: "https://github.com/PlaceholderAPI/PlaceholderAPI",
        headers: { "App-Package": "com.morabaa.reps", Authorization: localStorage.getItem("token") },
    },
];
export default class Urls {
    static hub = {
        baseURL: "https://hubcore.morabaaapps.com/api/v1",
        headers: { "App-Package": "com.morabaa.hub", Authorization: localStorage.getItem("token") },
    };

    static items = {
        baseURL: "https://items.morabaaapps.com/api/v1",
        headers: { "App-Package": "com.morabaa.items", Authorization: localStorage.getItem("token") },
    };

    static sales = {
        baseURL: "https://salereports.morabaaapps.com/api/v1",
        headers: { "App-Package": "com.morabaa.items", Authorization: localStorage.getItem("token") },
    };

    static reps = test[0];
    static test = {
        baseURL: "https://jsonplaceholder.typicode.com",
        headers: { "App-Package": "com.morabaa.reps", Authorization: localStorage.getItem("token") },
    };
    // static log = {baseURL:"https://falcon.samwise.morabaa.com/api/v1"}
    // static log = {baseURL:"http://localhost:18699/api/v1"}
}
