import React from "react";
import Root from "../scripts/Root";

const ToogleTheme = () => {
    return (
        <p
            className="bg-prim rounded-md p-sm text-center cursor-pointer shadow-lg"
            onClick={({ currentTarget }) => {
                Root.toggleTheme();
                currentTarget.innerText = Root.theme === "dark" ? "🌙" : "🌞";
            }}>
            {Root.theme === "dark" ? "🌙" : "🌞"}
        </p>
    );
};

export default ToogleTheme;
