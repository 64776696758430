// { title: "الايقونة", id: "lastVersion" },
// { title: "اخر لوك", id: "lastLogAt" },
// { title: "اخر ايرور", id: "lastErrorAt" },
// { title: "عدد اللوك", id: "logsCount" },
// { title: "عدد الايرور", id: "errorsCount" },
// { title: "عدد الدناكل", id: "donglesCount" },
// { title: "عدد المستخدمين", id: "usersCount" },
const Language = {
    CompanyName: "اسم الشركة",
    DongleName: "اسم الدنكل",
    DongleId: "الدنكل",
    Source: "المصدر",
    TheMessage: "الرسالة",
    Content: "المحتوى",
    Date: "التاريخ",
    Time: "الوقت",
    Version: "الاصدار",
    Error: "الايرور",
    Fatal: "الفاتل",
    Info: "معلومة",
    Warn: "تحذير",
    All: "الكل",
    AllStatus: "كل الحالات",
    WithoutErrorStatus: "بدون حالة",

    LastVersion: "الايقونة",
    LastLog: "اخر لوك",
    LastError: "اخر ايرور",
    LogsCount: "عدد اللوك",
    ErrorsCount: "عدد الايرور",
    DonglesCount: "عدد الدناكل",
    UsersCount: "عدد المستخدمين",

    Without: "بدون",
    WithoutRepetitions: "بدون تكرار",
    Level: "المستوى",

    CountByBusinesses: "عدد الشركات",
    Repetitions: "التكرارات",
};
export default Language;
