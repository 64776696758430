import React from "react";
import Button from "../Components/Button";
import JsonToView from "../NodeBuilder/JsonToView";
import PageStateKit from "../PageStateKit/PageStateKit";
import { ServiceStateBuilder } from "../ServiceProvider";

const States = Object.keys(PageStateKit);

const ServiceStateBuilderExample = () => {
    const service = { state: "none", setState: () => {} };

    return (
        <div className="col gap-md p-md relative h-fill">
            <JsonToView className="row-center wrap bg-prim gap-md p-md rounded-xl" json={States} />
            <div className="row-center gap-2xl p-xl">
                {States.map((state) => (
                    <Button
                        key={state}
                        title={state}
                        onClick={() => {
                            service.setState(state);
                        }}
                    />
                ))}
            </div>

            <ServiceStateBuilder service={service} />
        </div>
    );
};

export default ServiceStateBuilderExample;
