import React from "react";
const CopyText = ({ info, infoTitle = "Dongle Id" }) => {
    const [copied, setCopied] = React.useState(false);
    return (
        <p className="flex font-bold text flex-row items-center ">
            {/* <span
                onClick={() => {
                    navigator.clipboard.writeText(info);
                    setCopied(true);
                    setTimeout(() => setCopied(false), 1500);
                }}
                className="material-icons pointer text-goat ">
                {copied ? "check" : "content_copy"}
            </span> */}
            <span className="text-[11px] pt-1 text-shark px-1">{infoTitle} : </span>
            <span className="text-[15px] px-0.5">{info}</span>
        </p>
    );
};

export default CopyText;
